import { Decision } from "./decisions.js";
import { n2s } from "./textHandler.js";

const goals_per_age = {
    Holstein:{
        kg:725,
        milk:5217 / 305,
        prot:3.06,
        fat:3.65,
    },
};




export class Cow{
    constructor(obj, id){
        this.id = id !== null ? id : obj.id;
        this.gender = obj.gender;
        this.breed = obj.breed;
        this.age = parseFloat(obj.age);
        this.days_spent_with_mother_as_calf = obj.dmc;
        this.weight = obj.weight;

        this.fertility = 1;
        this.inseminate = false;

        this.production_modifier = 0;
        this.daily_milk = 0;
        this.daily_prot = 0;
        this.daily_fat = 0;
        this.lactation_n = obj.random !== undefined && obj.age > 2 && obj.random > 0.5 ? 1 : 0;/*also used for males (num offspring)*/
        this.total_milk_production = 0;
        this.yearly_milk_production = 0;

        this.dry = false;
        this.days_after_dry = 0;
        this.lactating = obj.random !== undefined && obj.gender === "Female" && obj.age > 2 && obj.random > 0.5 ? true : false;
        this.days_after_calving = 0;
        this.inseminated = false;
        this.days_after_insemination = 0;

        this.sick = false;
        this.call_vet = false;
        this.force_feed = false;

        this.daily_food_need = {
            mcal:1,
            prot:1,
            vit:1,
            min:1,
            water:1,
        };
        this.daily_food_intake = {
            mcal:1,
            prot:1,
            vit:1,
            min:1,
            water:1,
        };

        this.comfort_level = 0;

        this.price = 1000;
        this.daily_cost = 100;

        this.genetic_factor = obj.gf;
        this.factor = 1;

        this.history = [];
        this.feedback = {
            cure:0,
            insemination:0,
            death:0,
            dry:0,
            lactating:0,
            inseminatable:0,
            delivered:0,
            new_comer:1,
            parturient:0,
            nofood:0,
        };
        
        this.sell = false;
    }
    diceRole(comfortModifiers, available_food, available_bull){
        if(this.gender === "Male"){
            this.lactating = false;
            this.days_after_calving = 0;
            this.inseminated = false;
        }
        if(!this.lactating) this.yearly_milk_production = 0;
        const randomness = Math.random();

        const history = new Array(this.history.length + 1);
        this.history.map((item, i) => history[i] = item);
        history[this.history.length] = {
            daily_milk: this.daily_milk,
            weight: this.weight,
        };
        this.history = history;

        this.feedback.insemination = 0;
        this.feedback.cure = 0;
        this.feedback.delivered = 0;
        this.feedback.inseminatable = 0;
        this.computeDeathChance(randomness);
        if(this.feedback.death !== 1){
            this.computeNeeds();
            this.computeFoodIntake(available_food);
            this.computeWeightIncrease(randomness, comfortModifiers);

            if(this.lactating){
                this.computeDailyProduction();
                this.days_after_insemination = 0;
                this.days_after_calving ++;
                this.total_milk_production += this.daily_milk;
                this.yearly_milk_production += this.daily_milk;
                if(this.days_after_calving > 305){
                    this.lactating = false;
                    this.days_after_calving = 0;
                    this.dry = true;
                    this.days_after_dry = 0;
                }
            }else{
                this.daily_milk = 0;
                this.daily_prot = 0;
                this.daily_fat = 0;
            }
            
            if(this.dry){
                this.days_after_dry ++;
                if(this.days_after_dry > 60){
                    this.dry = false;
                    this.days_after_dry = 0;
                }
            }
            if(this.inseminate){
                if(available_bull === 0) this.daily_cost += 0.5;
                if(this.gender === "Female" && !this.dry && !this.lactating) this.computeInsemination(randomness);
            }
            this.inseminate = false;

            if(this.sick && this.call_vet){
                this.computeSicknessCure(randomness);
            }

            if(this.sick){
                if(randomness < 0.1){
                    this.sick = false;
                    this.feedback.cure = 1;
                }
            }else if(!this.sick){
                if(randomness < (this.comfort_level * this.genetic_factor * 0.01)){
                    this.sick = true;
                }
            }

            if(this.inseminated && this.gender === "Female"){
                this.days_after_insemination ++;
                if(this.days_after_insemination > 265) this.feedback.parturient = 1;
                if(this.days_after_insemination > (265 + randomness * 15)){
                    this.days_after_insemination = 0;
                    this.lactating = true;
                    this.days_after_calving = 1;
                    this.lactation_n ++;
                    this.inseminated = false;
                    this.weight -= goals_per_age[this.breed].kg + randomness * 10;
                    if(this.weight < 75) this.weight = 75;
                    this.feedback.parturient = 0;
                    this.feedback.delivered = 1;
                }
            }else if(this.inseminated && this.gender === "Male"){
                this.inseminated = false;
            }

            this.computeFertility(randomness);
            this.computePrice();

            this.feedback.lactating = this.lactating ? 1 : 0;
            this.feedback.dry = this.dry ? 1 : 0;
            this.age += (1 / 365.25);
            this.sell = false;
            
            if(this.call_vet){
                this.daily_cost += 0.5;
                this.call_vet = false;
            }

            if(this.gender === "Female" && !this.lactating && !this.inseminated && !this.dry && this.age > 2) this.feedback.inseminatable = 1;
        }
    }
    computedcalmod(){
        return -0.00002699 * Math.pow(this.days_after_calving, 2) + (0.004953 * this.days_after_calving);
    }
    computedlacmod(){
        return -0.0125 * Math.pow(this.lactation_n, 2) + (0.075 * this.lactation_n);
    }
    computedmcalperkg(){
        return 0.0001392 * Math.pow(this.weight, 2) - (0.1325 * this.weight) + 31.35;
    }
    computekgperage(){
        return -0.7639 * Math.pow(this.age, 2) + (77.92 * this.age) + 47.22;
    }
    computeNeeds(){
        this.daily_food_need.mcal = this.computedmcalperkg();
        if(this.lactating) this.daily_food_need.mcal += 5;
        this.daily_food_need.prot = this.weight * 0.288;
        this.daily_food_need.vit = this.weight * 0.0453;
        this.daily_food_need.water = (this.weight * 11) + (this.daily_milk * 4.5);
        this.daily_food_need.min = (this.weight * 0.26) + (this.daily_milk * 0.002);
    }
    computeWeightIncrease(randomness, comfortModifiers){
        const comm = (comfortModifiers.reduce((a, b) => parseFloat(a) + parseFloat(b)));
        const lacm = this.computedlacmod();
        const dcalvm = this.computedcalmod();
        const sickm = this.sick ? -1 : 0;
        this.production_modifier = this.dry && !this.lactating ? 0 : Math.max(0, Math.round(1000 * (comm + lacm + dcalvm + sickm)) / 1000);

        const mcal_factor = this.daily_food_intake.mcal / this.daily_food_need.mcal;
        const prot_factor = this.daily_food_intake.prot / this.daily_food_need.prot;
        const vit_factor = this.daily_food_intake.vit / this.daily_food_need.vit;
        const water_factor = this.daily_food_intake.water / this.daily_food_need.water;
        const mineral_factor = this.daily_food_intake.min / this.daily_food_need.min;
        const dswmac = Math.min(14, this.days_spent_with_mother_as_calf) / 14;
        const f_tmp = this.genetic_factor * mcal_factor * prot_factor * vit_factor * mineral_factor * dswmac * water_factor * (this.sick ? 0.5 : 1);
        this.factor = (((randomness * f_tmp * 2) - f_tmp) / 100) + f_tmp;
        if(mcal_factor < 1 || prot_factor < 1 || vit_factor < 1 || mineral_factor < 1 || water_factor < 1) this.feedback.nofood++;
        else this.feedback.nofood = 0;

        if(water_factor < 0) this.feedback.nofood += 5;

        this.weight += this.feedback.nofood < 1 ? ((this.computekgperage() - this.weight) / this.weight) * (Math.cosh(this.factor) - 1) : (-1 * this.weight / 60);
        if(this.weight < 10) this.weight = 10;

        this.comfort_level = Math.round(1000 * (comm + sickm + this.factor)) / 1000;
    }
    computeDailyProduction(){
        const computedProduction = {
            milk:((goals_per_age[this.breed].milk / goals_per_age[this.breed].kg) * this.weight) * this.factor,
            prot:goals_per_age[this.breed].prot * this.factor,
            fat:goals_per_age[this.breed].fat * this.factor,
        };
        this.daily_milk = computedProduction.milk + (computedProduction.milk * this.production_modifier);
        this.daily_prot = computedProduction.prot;
        this.daily_fat = computedProduction.fat;
    }
    computeInsemination(randomness){
        if(randomness * this.fertility > 0.5 && this.age > 2){
            this.feedback.insemination = 1;
            this.inseminated = true;
        }else{
            this.feedback.insemination = 2;
        }
    }
    computeFertility(randomness){
        let f = this.fertility;
        f -= ((0.01 * randomness) / 365.25) * (1 - this.genetic_factor);
        this.fertility = Math.max(0, f);
    }
    computeSicknessCure(randomness){
        if(randomness > (0.5 * (1 - this.genetic_factor))){
            this.feedback.cure = 1;
            this.sick = false;
        }else{
            this.feedback.cure = 2;
        }
    }
    computeDeathChance(randomness){
        const y = (0.000498 * Math.pow(this.age, 2) - (0.01488 * this.age) + (0.1 / (365.25 * this.genetic_factor)));
        const i = this.sick ? randomness - 0.01 : randomness;
        if(i < y || this.feedback.nofood > 20){
            this.feedback.death = 1;
            this.dry = false;
            this.lactating = false;
            this.inseminated = false;
            this.sick = false;
            this.comfort_level = 0;
            this.price = 0;
            this.feedback.lactating = 0;
            this.feedback.dry = 0;
            this.feedback.inseminatable = 0;
            this.feedback.parturient = 0;
            this.daily_food_need = {
                mcal:0.00001,
                prot:0.00001,
                vit:0.00001,
                min:0.00001,
                water:0.00001,
            };
            this.daily_food_intake = {
                mcal:0.00001,
                prot:0.00001,
                vit:0.00001,
                min:0.00001,
                water:0.00001,
            };
            this.daily_cost = 0.00001;
            this.daily_milk = 0;
            this.daily_prot = 0;
            this.daily_fat = 0;
            this.fertility = 0;
        }
    }
    computePrice(){
        this.price = ((7 / Math.max(1, this.age)) * this.weight * goals_per_age[this.breed].kg * Math.max(1, this.daily_milk) * goals_per_age[this.breed].milk * Math.max(1, this.daily_prot) * Math.max(1, this.daily_fat) * Math.max(0.2, this.production_modifier) * this.genetic_factor * this.fertility * (Math.max(1, this.lactation_n) / 4)) / 400000;
    }
    computeFoodIntake(available_food){
        this.daily_cost = 0.00001;
        const obj = {
            mcal: Math.min(available_food.mcal,  this.force_feed ? this.daily_food_need.mcal * 1.2 : this.daily_food_need.mcal),
            prot: Math.min(available_food.prot, this.force_feed ? this.daily_food_need.prot * 1.2 : this.daily_food_need.prot),
            min: Math.min(available_food.min, this.force_feed ? this.daily_food_need.min * 1.2 : this.daily_food_need.min),
            vit: Math.min(available_food.vit, this.force_feed ? this.daily_food_need.vit * 1.2 : this.daily_food_need.vit),
            water: Math.min(available_food.water, this.daily_food_need.water),
        };
        Object.keys(obj).forEach(key => {
            this.daily_food_intake[key] = obj[key];
            this.daily_cost += Math.max((obj[key] * available_food[(key + "p")]), 0.00001);
        });
    }
    loadCow(obj){
        this.gender = obj.gender;
        this.breed = obj.breed;
        this.age = obj.age;
        this.weight = obj.weight;
        this.days_spent_with_mother_as_calf = obj.days_spent_with_mother_as_calf;
        this.genetic_factor = obj.genetic_factor;
        this.fertility = obj.fertility;

        if(obj.dry !== undefined) this.dry = obj.dry;

        if(obj.dry !== undefined) this.lactating = obj.lactating;
        if(obj.dry !== undefined) this.days_after_calving = obj.days_after_calving;

        this.lactation_n = obj.lactation_n;

        this.sick = obj.sick;
        if(obj.days_after_dry !== undefined) this.days_after_dry = obj.days_after_dry;
        if(obj.inseminate !== undefined) this.inseminate = obj.inseminate;
        if(obj.production_modifier !== undefined) this.production_modifier = obj.production_modifier;
        if(obj.daily_milk !== undefined) this.daily_milk = obj.daily_milk;
        if(obj.daily_prot !== undefined) this.daily_prot = obj.daily_prot;
        if(obj.daily_fat !== undefined) this.daily_fat = obj.daily_fat;
        if(obj.total_milk_production !== undefined) this.total_milk_production = obj.total_milk_production;
        if(obj.yearly_milk_production !== undefined) this.yearly_milk_production = obj.yearly_milk_production;
        if(obj.inseminated !== undefined) this.inseminated = obj.inseminated;
        if(obj.days_after_insemination !== undefined) this.days_after_insemination = obj.days_after_insemination;

        if(obj.call_vet !== undefined) this.call_vet = obj.call_vet;
        if(obj.force_feed !== undefined) this.force_feed = obj.force_feed;

        if(obj.daily_food_need !== undefined) this.daily_food_need = obj.daily_food_need;
        if(obj.daily_food_intake !== undefined) this.daily_food_intake = obj.daily_food_intake;

        if(obj.comfort_level !== undefined) this.comfort_level = obj.comfort_level;

        if(obj.price !== undefined) this.price = obj.price;
        if(obj.daily_cost !== undefined) this.daily_cost = obj.daily_cost;

        this.genetic_factor = obj.genetic_factor;
        if(obj.factor !== undefined) this.factor = obj.factor;

        if(obj.history !== undefined) this.history = obj.history;
        if(obj.feedback !== undefined) this.feedback = obj.feedback;
        
        if(obj.sell !== undefined) this.sell = obj.sell;
    }
}





export class Herd{
    constructor(cm, treasury){
        this.herd = [];
        this.milk_price = 0.0085;
        this.treasury = treasury;
        this.autoPurchase = [false, false, false, false];
        this.market_actions = {
            sold_cattle:0,
            feed_purchases:0,
            cattle_purchases:0,
            opened_future:0,
        };
        this.total_milk_production = 0;
        this.feed = {
            mcal:0,
            mcalp:0.00001,
            prot:0,
            protp:0.00001,
            vit:0,
            vitp:0.00001,
            min:0,
            minp:0.00001,
            water:0,
            waterp:0.00001,
        };
        this.feed_source = {
            mcal:[0, 0, 0, 0],
            prot:[0, 0, 0, 0],
            vit:[0, 0, 0, 0],
            min:[0, 0, 0, 0],
            feed_cm:[0, 0, 0, 0],
        };
        this.comfort_modifiers = cm;
        this.open_futures = [];
        this.closed_futures = [];
        this.comfort = 0;
        this.total_daily_food_need = {
            mcal:7,
            prot:1,
            vit:1,
            min:1,
            water:1,
        };
        this.total_daily_food_intake = {
            mcal:7,
            prot:1,
            vit:1,
            min:1,
            water:1,
        };
        this.table = [
            [[[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[]]],
            [],
        ];
        this.subtable = [
            [0,0,0,0,0,0,0,0,0,0,0,0,0],
            [],
        ];
        this.chart = [
            [[{value: 0, date: 0}], [], [{value: 0, date: 0}], [{value: this.treasury, date: 0}]],
            [[],[]],
        ];
        this.inventory = [];
        this.history = [];
        this.clean_dead = [];
        this.increment = 0;
        this.reproductors = [];
        this.decision = new Decision();
    }
    initialize(){
        if(this.clean_dead.length > 0){
            this.clean_dead.sort((a, b) => b - a).forEach(item => this.herd.splice(item, 1));
            this.clean_dead = [];
        }

        this.handleFutures();

        this.reproductors = [];

        let new_comers = [];
        this.table[1] = [];
        const next_chart = [
            [new Array(this.chart[0][0].length + 1), new Array(this.herd.length), new Array(this.chart[0][2].length + 1), new Array(this.chart[0][3].length + 1)],
            [[],[]],
        ];
        for(let i = 0; i < this.chart[0][0].length; i++){
            next_chart[0][0][i] = this.chart[0][0][i];
            next_chart[0][2][i] = this.chart[0][2][i];
            next_chart[0][3][i] = this.chart[0][3][i];
        }

        const history = new Array(this.history.length + 1);
        this.history.map((item, i) => history[i] = item);
        history[this.history.length] = [[[
            this.table[0][0][0],
            this.table[0][0][1],
            this.table[0][0][2],
            this.table[0][0][3],
            this.table[0][0][4],
            this.table[0][0][5],
            this.table[0][0][6],
            this.table[0][0][7],
        ]]];
        this.history = history;

        this.table[0][0][0] = [this.history.length, this.history.length];
        this.table[0][0][1] = [this.herd.length, this.history[this.history.length - 1][0][0][1][0]];
        this.table[0][0][2] = [0, 0];
        this.table[0][0][3] = [0, 0];
        this.table[0][0][4] = [0, 0];
        this.table[0][0][5] = [0, 0];
        this.table[0][0][6] = [0, 0];

        this.subtable = [
            [0,0,0,0,0,0,0,0,0,0,0,0,0],
            [],
        ];

        this.total_daily_food_need.mcal = 0.00001;
        this.total_daily_food_intake.mcal = 0.00001;
        this.total_daily_food_need.prot = 0.00001;
        this.total_daily_food_intake.prot = 0.00001;
        this.total_daily_food_need.vit = 0.00001;
        this.total_daily_food_intake.vit = 0.00001;
        this.total_daily_food_need.min = 0.00001;
        this.total_daily_food_intake.min = 0.00001;
        this.total_daily_food_need.water = 0.00001;
        this.total_daily_food_intake.water = 0.00001;

        this.comfort = 0;

        this.herd.map((item, idx) => {
            item.diceRole(this.comfort_modifiers, this.feed, this.reproductors.length);

            this.total_daily_food_need.mcal += item.daily_food_need.mcal;
            this.total_daily_food_intake.mcal += item.daily_food_intake.mcal;

            this.total_daily_food_need.prot += item.daily_food_need.prot;
            this.total_daily_food_intake.prot += item.daily_food_intake.prot;

            this.total_daily_food_need.vit += item.daily_food_need.vit;
            this.total_daily_food_intake.vit += item.daily_food_intake.vit;

            this.total_daily_food_need.min += item.daily_food_need.min;
            this.total_daily_food_intake.min += item.daily_food_intake.min;

            this.total_daily_food_need.water += item.daily_food_need.water;
            this.total_daily_food_intake.water += item.daily_food_intake.water;

            this.table[0][0][3][0] += item.daily_cost;
            this.table[0][0][4][0] += (((100 * item.daily_food_intake.mcal) / item.daily_food_need.mcal) / this.herd.length);
            this.table[0][0][5][0] += (((100 * item.daily_food_intake.water) / item.daily_food_need.water) / this.herd.length);
            this.table[0][0][6][0] += item.daily_milk;

            this.total_milk_production += item.daily_milk;

            if(item.lactating) this.subtable[0][0]++;
            if(item.dry) this.subtable[0][1]++;
            if(item.feedback.new_comer === 1) this.subtable[0][2]++;
            if(item.feedback.parturient === 1) this.subtable[0][3]++;
            if(item.inseminated) this.subtable[0][4]++;
            if(item.feedback.inseminatable === 1) this.subtable[0][5]++;
            if(item.sick) this.subtable[0][6]++;
            if(item.feedback.cure === 1) this.subtable[0][7]++;
            if(item.feedback.death === 1) this.subtable[0][8]++;
            if(item.gender === "Female") this.subtable[0][9]++;
            if(item.gender === "Male"){
                this.subtable[0][10]++;
                if(item.age >= 1){
                    const nr = new Array(this.reproductors.length + 1);
                    for(let j = 0; j < nr.length; j++){
                        nr[j] = this.reproductors[j] !== undefined ? this.reproductors[j] : {idx: idx, id: item.id, gf: item.genetic_factor, f: item.fertility};
                    }
                    this.reproductors = nr;
                }
            }
            if(item.age <= 2) this.subtable[0][11]++;
            this.subtable[0][12]++;

            /*change mother's genetic_factor on insemination (for offspring)*/
            if(item.feedback.insemination === 1 && this.reproductors.length > 0){
                const f_gf = item.genetic_factor;
                const bull = this.reproductors[Math.round(Math.random() * (this.reproductors.length - 1))];
                if(bull !== undefined){
                    if(this.herd[bull.idx] !== undefined && this.herd[bull.idx].id === bull.id) this.herd[bull.idx].lactation_n += 1;
                    item.genetic_factor = (bull.gf + f_gf) / 2;
                }else{
                    item.genetic_factor = (1 + f_gf) / 2;
                }
            }

            const w_itenum = Math.min(7, item.history.length);
            
            const res = {
                cure: item.feedback.cure,
                insemination: item.feedback.insemination,
                death: item.feedback.death,
                dry: item.feedback.dry,
                lactating: item.feedback.lactating,
                inseminatable: item.feedback.inseminatable,
                new_comer: item.feedback.new_comer,
                parturient: item.feedback.parturient,
                inseminated: item.inseminated,
                gender: item.gender,
            };
            const tbl_1_tmp = [
                [item.id, 0],
                [item.breed, 0],
                [item.age, 0],
                [item.comfort_level, res, item.sick],
                [item.price, item.price - item.history[item.history.length - 1].price],
                [item.daily_milk, item.daily_milk - item.history[item.history.length - 1].daily_milk],
                new Array(w_itenum),
            ];
            for(let i = 0; i < w_itenum; i++){
                tbl_1_tmp[6][i] = {value:item.history[(item.history.length - w_itenum) + i].daily_milk};
            }
            tbl_1_tmp[6][w_itenum] = {value:item.daily_milk};

            this.table[1][idx] = tbl_1_tmp;

            this.comfort += (item.comfort_level / this.table[0][0][1][0]);

            next_chart[0][1][idx] = {x: item.daily_prot, y: item.daily_fat, z :item.daily_milk, id:item.id};
            
            if(item.feedback.death === 1){
                const n_cd = new Array(this.clean_dead.length + 1);
                for(let i = 0; i < this.clean_dead.length; i++){
                    n_cd[i] = this.clean_dead[i];
                }
                n_cd[this.clean_dead.length] = idx;
                this.clean_dead = n_cd;
            }
            if(item.feedback.delivered === 1){
                const nc = new Array(new_comers.length + 1)
                new_comers.map((el, i) => nc[i] = el);
                nc[new_comers.length] = {breed: item.breed, age: 0.0001, weight: item.genetic_factor * 50, dmc: 14, gf: item.genetic_factor};
                new_comers = nc;
            }
            item.feedback.new_comer = 0;
            return item;
        });

        if(this.closed_futures.length === 0){
            this.table[0][0][2][0] = this.table[0][0][6][0] * this.milk_price;
        }else{
            this.table[0][0][2][0] = this.computeDailyMilkAgainstFutures(this.table[0][0][6][0]);
        }

        const w_1_itenum = Math.min(7, this.history.length);
        this.table[0][0][7] = new Array(w_1_itenum);
        this.table[0][0].forEach((cell, j) => {
            if(j !== 7){
                return cell[1] = (cell[0] - this.history[this.history.length - 1][0][0][j][0]);
            }else{
                for(let i = 0; i < w_1_itenum; i++){
                    cell[i] = {value:this.history[(this.history.length - w_1_itenum) + i][0][0][6][0]};
                }
            }
        });
        this.table[0][0][7][w_1_itenum] = {value:this.table[0][0][6][0]};

        const remaining_feed = {
            mcal:0,
            prot:0,
            vit:0,
            min:0,
            water:0,
        };
        Object.keys(remaining_feed).forEach(key => {
            remaining_feed[key] = Math.max(this.feed[key] - this.total_daily_food_intake[key], 0);
            remaining_feed[(key + "p")] = !isNaN(this.feed[(key + "p")]) ? this.feed[key + "p"] : 0.00001;
        });

        this.feed = remaining_feed;

        const gross_income = this.table[0][0][2][0] + this.market_actions.sold_cattle + (this.market_actions.opened_future >= 0 ? this.market_actions.opened_future : 0);
        const total_costs = (this.table[0][0][3][0] * -1) + this.market_actions.feed_purchases + this.market_actions.cattle_purchases + (this.market_actions.opened_future < 0 ? this.market_actions.opened_future : 0);
        const total_profit = gross_income + total_costs;

        this.treasury += this.table[0][0][2][0];

        const food_cm = this.comfort_modifiers.reduce((a, b) => a + b);

        this.inventory = [
            {
                main:this.treasury,
                sec:total_profit,
                sub:[(this.table[0][0][3][0] * -1), this.market_actions.feed_purchases, this.market_actions.cattle_purchases, this.market_actions.opened_future, this.market_actions.sold_cattle, this.table[0][0][2][0], total_profit],
            },{
                main:this.feed.mcal,
                sec:this.total_daily_food_intake.mcal,
                sub:[this.feed_source.mcal[0] * this.total_daily_food_intake.mcal, this.feed_source.mcal[1] * this.total_daily_food_intake.mcal, this.feed_source.mcal[2] * this.total_daily_food_intake.mcal, this.feed_source.mcal[3] * this.total_daily_food_intake.mcal,],
            },{
                main:this.feed.prot,
                sec:this.total_daily_food_intake.prot,
                sub:[this.feed_source.prot[0] * this.total_daily_food_intake.prot, this.feed_source.prot[1] * this.total_daily_food_intake.prot, this.feed_source.prot[2] * this.total_daily_food_intake.prot, this.feed_source.prot[3] * this.total_daily_food_intake.prot,],
            },{
                main:this.feed.vit,
                sec:this.total_daily_food_intake.vit,
                sub:[this.feed_source.vit[0] * this.total_daily_food_intake.vit, this.feed_source.vit[1] * this.total_daily_food_intake.vit, this.feed_source.vit[2] * this.total_daily_food_intake.vit, this.feed_source.vit[3] * this.total_daily_food_intake.vit,],
            },{
                main:this.feed.min,
                sec:this.total_daily_food_intake.min,
                sub:[this.feed_source.min[0] * this.total_daily_food_intake.min, this.feed_source.min[1] * this.total_daily_food_intake.min, this.feed_source.min[2] * this.total_daily_food_intake.min, this.feed_source.min[3] * this.total_daily_food_intake.min,],
            },{
                main:this.feed.water,
                sec:this.total_daily_food_intake.water,
                sub:[this.total_daily_food_intake.water, this.total_daily_food_intake.water / Math.max(this.herd.length, 1)],
            },{
                main:this.table[0][0][1][0],
                sec:null,
                sub:[this.subtable[0][9], this.subtable[0][10]],
            },{
                main:this.table[0][0][6][0],
                sec:null,
                sub:[this.table[0][0][6][0], this.table[0][0][6][0]],
            },{
                main:this.comfort * 100,
                sec:null,
                sub:[(this.comfort - food_cm) * 100, this.comfort_modifiers[0] * 100, this.comfort_modifiers[1] * 100, this.comfort_modifiers[2] * 100, this.comfort_modifiers[3] * 100, this.decision.active ? ((n2s(this.decision.valid, 1, true)) + " days left") : "inactive"],
            },
        ];

        next_chart[0][0][this.chart[0][0].length] = {value: this.table[0][0][6][0], date: this.history.length};
        next_chart[0][2][this.chart[0][2].length] = {value: Math.max(0, total_profit), date: this.history.length};
        next_chart[0][3][this.chart[0][3].length] = {value: Math.max(0, parseFloat(this.treasury)), date: this.history.length};
        this.chart = next_chart;

        this.addHeads(new_comers);

        this.market_actions.sold_cattle = 0;
        this.market_actions.feed_purchases = 0;
        this.market_actions.cattle_purchases = 0;
        this.market_actions.opened_future = 0;
    }
    handleFutures(){
        for(let i = 0; i < this.open_futures.length; i++){
            this.open_futures[i].initialize(this.milk_price, true);
        }
    }
    computeDailyMilkAgainstFutures(kg){
        let total_milk = parseFloat(kg);
        for(let i = 0; i < this.closed_futures.length; i++){
            total_milk -= this.closed_futures[i].kg_milk;
        }
        if(total_milk < 0) this.market_actions.opened_future -= (Math.abs(total_milk) * this.milk_price);
        return total_milk > 0 ? total_milk * this.milk_price : 0;
    }
    addHeads(array, x){/*x = "load", "buy" or "new"*/
        if(array.length > 0){
            if(x === "buy") this.market_actions.cattle_purchases = 0;
            const new_herd = new Array(this.herd.length + array.length);
            for(let i = 0, j = 0; i < new_herd.length; i ++){
                if(this.herd[i] !== undefined){
                    new_herd[i] = this.herd[i];
                }else{
                    new_herd[i] = new Cow(array[j], x === "load" ? null : this.increment);
                    if(x === "load" || x === "buy") new_herd[i].loadCow(array[j]);
                    if(x === "buy"){
                        const price = array[j].price;
                        this.treasury -= price;
                        this.market_actions.cattle_purchases -= price;
                    }
                    if(x !== "load") this.increment ++;
                    j ++;
                }
            }
            this.herd = new_herd;
        }
    }
    generateIndividualData(bos_index){
        const b = this.herd[bos_index];
        let av_prod = 0;
        if(b.lactation_n <= 1){
            const a = b.history.filter(item => item.days_after_calving === 306);
            const a_l = a.length;
            av_prod = a_l > 0 ? a.reduce((a, b) => (a.yearly_milk_production + b.yearly_milk_production) / a_l) : 0;
        }
        const h_l = b.history.length;
        const weight_change = h_l <= 1 ? 0 : b.weight - b.history[h_l - 1].weight;
        this.subtable[1] = [
            b.total_milk_production,
            av_prod,
            b.daily_milk * 305,
            b.genetic_factor * 100,
            b.age,
            b.weight,
            weight_change,
            b.lactation_n,
            b.fertility * 100,
            b.comfort_level * 100,
            b.production_modifier * 100,
            b.lactating ? "YES" : "NO",
            b.days_after_calving,
            b.dry ? "YES" : "NO",
            b.days_after_dry,
            b.feedback.inseminatable === 0 ? "NO" : "YES",
            ((b.inseminated ? "YES" : "NO") + (b.feedback.insemination === 2 ? " (INSEMINATION FAILED)" : b.feedback.insemination === 1 ? " (INSEMINATION SUCCEEDED)" : "")),
            b.days_after_insemination,
            b.feedback.parturient === 0 ? "NO" : "YES",
            b.feedback.parturient === 0 ? 0 : 305 - b.days_after_insemination,
            ((b.sick ? "YES" : "NO") + (b.feedback.cure === 2 ? " (CURE FAILED)" : b.feedback.cure === 1 ? " (CURE SUCCEEDED)" : "")),
            b.daily_cost
        ];
        
        const rest = 100 - b.daily_prot - b.daily_fat;
        this.chart[1][0] = [
            {value: b.daily_prot, name: "Protein"},
            {value: b.daily_fat, name: "Fat"},
            {value: b.daily_milk > 0 ? (rest * 0.055) : 0, name: "Lactose"},
            {value: b.daily_milk > 0 ? (rest * 0.013) : 0, name: "Ash"},
            {value: b.daily_milk > 0 ? (rest * 0.932) : 0, name: "Water"},
        ];

        this.chart[1][1] = new Array(b.history.length + 1);
        b.history.map((item, i) => this.chart[1][1][i] = {value:item.daily_milk, date: i});
        this.chart[1][1][b.history.length] = {value: b.daily_milk, date: h_l};
    }
    updateMilkPrice(mp){
        this.milk_price = mp;
    }
    manage(selection_idx, bos_idx){
        if(this.herd[bos_idx] !== undefined){
            switch(selection_idx){
                case 0:
                    this.herd[bos_idx].inseminate = !this.herd[bos_idx].inseminate;
                    break;
                case 1:
                    this.herd[bos_idx].call_vet = !this.herd[bos_idx].call_vet;
                    break;
                case 2:
                    this.herd[bos_idx].force_feed = !this.herd[bos_idx].force_feed;
                    break;
                case 3:
                    this.herd[bos_idx].sell = !this.herd[bos_idx].sell;
                    break;
                default:
                    break;
            }
        }
    }
    sell(idx_array){
        this.market_actions.sold_cattle = 0;
        idx_array.sort((a, b) => b - a).forEach(item => {
            if(this.herd[item] !== undefined){
                this.treasury += this.herd[item].price;
                this.market_actions.sold_cattle += this.herd[item].price;
                if(this.herd[item].gender === "Male" && this.herd[item].age >= 1){
                    const i = this.findWithAttr(this.reproductors, "id", this.herd[item].id);
                    if(i > -1) this.reproductors.splice(i, 1);
                }
                this.herd.splice(item, 1);
            }
        });
    }
    findWithAttr(array, attr, value) {
        for(let i = 0; i < array.length; i ++){
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    updateInventory(market_wares, bought_wares, autoPurchase, all_wares, feed_source){
        this.decision.nextTurn();

        this.autoPurchase = autoPurchase;

        this.feed = all_wares.feed;
        this.open_futures = all_wares.futures;
        this.feed_source = feed_source;
        if(all_wares.cattle.length > 0) this.addHeads(all_wares.cattle, "buy");

        this.market_actions.feed_purchases = 0;
        this.comfort_modifiers = new Array(market_wares.feed.length).fill(0);

        for(let i = 0; i < market_wares.feed.length; i++){
            const price = market_wares.feed[i].price * bought_wares.feed[i];
            this.treasury -= price;
            this.market_actions.feed_purchases -= price;

            this.comfort_modifiers[i] = this.feed.mcal > 1 && this.feed_source.mcal[i] > 0.01 ? this.handleDecisionModifiers(i) : 0;
        }
        if(this.feed.water >= this.total_daily_food_need.water) this.comfort_modifiers[3] = this.feed_source.feed_cm[3];
        else this.comfort_modifiers[3] = -1;

        let closed_futures = [];
        let open_futures = [];

        this.market_actions.opened_future = 0;

        this.open_futures.forEach((item, i) => {
            if(item.status === "CLOSED" || item.days_to_expiry <= 0){
                const nr = new Array(closed_futures.length + 1);
                for(let j = 0; j < closed_futures.length; j++){
                    nr[j] = closed_futures[j];
                }
                nr[closed_futures.length] = item;
                closed_futures = nr;
            }else{
                const nr = new Array(open_futures.length + 1);
                for(let j = 0; j < open_futures.length; j++){
                    nr[j] = open_futures[j];
                }
                nr[open_futures.length] = item;
                if(nr[open_futures.length].bought){
                    this.treasury += item.price;
                    this.market_actions.opened_future += item.price;
                }
                nr[open_futures.length].bought = false;
                open_futures = nr;
            }
        });
        this.open_futures = open_futures;
        this.closed_futures = closed_futures;
    }
    groupAction(nav){
        const sell_indices = [];
        for(let i = 0; i < this.herd.length; i ++){
            switch(nav){
                case 4:
                    if(this.herd[i].feedback.inseminatable === 1) this.herd[i].inseminate = true;
                    break;
                case 7:
                    if(this.herd[i].gender === "Male" && !this.herd[i].sell){
                        this.herd[i].sell = true;
                        sell_indices.push(i);
                    }
                    break;
                case 9:
                    if(this.herd[i].sick) this.herd[i].call_vet = true;
                    break;
                default:
                    break;
            }
        }
        return sell_indices;
    }

    loadSaveGame(obj, version){
        try{
            if(obj.version === version && obj !== undefined && obj.herd !== undefined && obj.herd.herd !== undefined){
                if(!isNaN(obj.herd.milk_price) && Array.isArray(obj.herd.herd) && obj.herd.herd.length >= 0){
                    this.milk_price = parseFloat(obj.herd.milk_price);
                    this.herd = new Array(obj.herd.herd.length);
                    for(let i = 0; i < obj.herd.herd.length; i++){
                        const tmp = obj.herd.herd[i];
                        const new_bos = new Cow({gender: tmp.gender, breed: tmp.breed, age: tmp.age, weight: tmp.weight, dmc:tmp.days_spent_with_mother_as_calf, gf: tmp.genetic_factor}, tmp.id);
                        new_bos.loadCow(tmp);
                        this.herd[i] = new_bos;
                    }
                    this.milk_price = obj.herd.milk_price;
                    this.treasury = obj.herd.treasury;
                    this.autoPurchase = obj.herd.autoPurchase;
                    this.market_actions = obj.herd.market_actions;
                    this.total_milk_production = obj.herd.total_milk_production;
                    this.feed = obj.herd.feed;
                    this.feed_source = obj.herd.feed_source;
                    this.comfort_modifiers = obj.herd.comfort_modifiers;

                    this.comfort = obj.herd.comfort;
                    this.total_daily_food_need = obj.herd.total_daily_food_need;
                    this.total_daily_food_intake = obj.herd.total_daily_food_intake;
                    this.table = obj.herd.table;
                    this.subtable = obj.herd.subtable;
                    this.chart = obj.herd.chart;
                    this.inventory = obj.herd.inventory;
                    this.history = obj.herd.history;
                    this.clean_dead = obj.herd.clean_dead;
                    this.increment = obj.herd.increment;
                    this.reproductors = obj.herd.reproductors;

                    this.open_futures = new Array(obj.herd.open_futures.length);
                    for(let i = 0; i < obj.herd.open_futures.length; i++){
                        this.open_futures[i] = new StoreFuture();
                        this.open_futures[i].loadFuture(obj.herd.open_futures[i]);
                    }
                    this.closed_futures = new Array(obj.herd.closed_futures.length);
                    for(let i = 0; i < obj.herd.closed_futures.length; i++){
                        this.closed_futures[i] = new StoreFuture();
                        this.closed_futures[i].loadFuture(obj.herd.closed_futures[i]);
                    }

                    this.decision = new Decision();
                    this.decision.load(obj.herd.decision);

                    return "success";
                }
            }else{
                return "error";
            }
        }catch(e){
            return "error";
        }
    }
    handleDecisionModifiers(i){
        if(!this.decision.active) return this.feed_source.feed_cm[i];
        switch(this.decision.current.effect.type){
            case "comfort":
                return (this.feed_source.feed_cm[i] + (Math.max(this.feed_source.feed_cm[i], 1) * this.decision.effect));
            default:
                return this.feed_source.feed_cm[i];
        }
    }
}




export class MarketPlace{
    constructor(treasury, last_milk_price, day, history, fbb, futures){
        this.interval = 15000;
        this.treasury = treasury;
        this.fraction = 20000;
        this.market_interval = null;
        this.flat_bull_bear = fbb;
        this.today = new Date(day).getTime();
        this.milk = [
            {
                value:last_milk_price,
                date:new Date(day).getTime(),
            },
        ];
        this.milk_history = history;
        this.wares = {
            futures:new Array(4),
            cattle:new Array(4),
            feed:new StoreFeed().feed,
        }
        this.open_futures = futures;
    }
    update(){
        const g = this.flat_bull_bear[0] === 0 ? Math.random() : this.flat_bull_bear[0] === 1 ? 1 : 0;
        const random = g > 0.5 ? Math.random() : g < 0.5 ? Math.random() - 1 : 0;
        const l = this.milk.length - 1;
        const new_milk = new Array(this.milk.length);
        for(let i = 0, j = 1; i < this.milk.length; i++, j++){
            new_milk[i] = this.milk[j];
        }
        new_milk[l] = {
            value:this.milk[l].value + (random / this.fraction),
            date:this.milk[l].date + this.interval,
        };
        this.milk = new_milk;
        if(this.milk[l].date >= (this.today + 86400000)) this.quit();
    }
    initialize(){
        clearInterval(this.market_interval);
        this.market_interval = null;
        const l = this.milk.length - 1;

        const new_milk_history = new Array(this.milk_history.length + 1);
        this.milk_history.map((item, i) => new_milk_history[i] = item);
        new_milk_history[this.milk_history.length] = this.milk[l];
        this.milk_history = new_milk_history;

        this.initializeMarket(l);
        const next_day = new Date(this.today);
        next_day.setDate(next_day.getDate() + 1);
        next_day.setHours(4);
        next_day.setMinutes(0);
        this.today = next_day.getTime();
        const value = this.milk[l].value;

        const iter_number = (this.today - this.milk[l].date - this.interval) / (this.interval);

        const entries = new Array(iter_number + 1);

        entries[0] = this.milk[0];

        for(let dmsec = this.milk[l].date + this.interval, i = 1; dmsec < this.today; dmsec += this.interval, i++){
            const g = this.flat_bull_bear[0] === 0 ? Math.random() : this.flat_bull_bear[0] === 1 ? 1 : 0;
            const random = g > 0.5 ? Math.random() : g < 0.5 ? Math.random() - 1 : 0;
            entries[i] = {
                value:value + (random / this.fraction),
                date:dmsec,
            };
        }

        this.milk = entries;
        this.market_interval = setInterval(this.update.bind(this), this.interval);
    }
    quit(){
        clearInterval(this.market_interval);
        this.market_interval = null;
    }
    initializeMarket(l){
        this.flat_bull_bear[1]++;
        if(this.milk[l].value > 0.0002){
            const clone = JSON.parse(JSON.stringify(this.flat_bull_bear));
            const g = Math.random();
            if((this.flat_bull_bear[1] * g) > 5){
                clone[0] = Math.round(g * 2);
            }
            if(clone[0] !== this.flat_bull_bear[0]){
                clone[1] = 0;
                this.flat_bull_bear = clone;
            }
        }else{
            this.flat_bull_bear = [1, 0];
        }
        this.generateWares();
    }
    generateWares(){
        for(let i = 0; i < 4; i++){
            const nf = new StoreFuture();
            nf.createNew(this.milk[this.milk.length - 1].value, i);
            this.wares.futures[i] = nf;
            const nc = new StoreCow();
            nc.initialize();
            this.wares.cattle[i] = nc;
        }
    }
    closeTrades(new_qty, old_herd_feed, old_herd_feed_source){
        const [fsum, catsum, futsum] = this.getTotal([new_qty.feed, new_qty.cattle, new_qty.futures]);
        
        const wares = {
            feed: {
                mcal:old_herd_feed.mcal,
                mcalp:old_herd_feed.mcalp,
                prot:old_herd_feed.prot,
                protp:old_herd_feed.protp,
                vit:old_herd_feed.vit,
                vitp:old_herd_feed.vitp,
                min:old_herd_feed.min,
                minp:old_herd_feed.vitp,
                water:old_herd_feed.water + (this.wares.feed[3].kg * new_qty.feed[3]),
                waterp:this.wares.feed[3].price / this.wares.feed[3].kg,
            },
            cattle: [],
            futures: [],
        };

        const feed_source = {
            mcal:[0, 0, 0, 0],
            prot:[0, 0, 0, 0],
            vit:[0, 0, 0, 0],
            min:[0, 0, 0, 0],
            feed_cm:[0, 0, 0, 0],
        };

        if(fsum > 0.00001 || futsum > 0.00001 || catsum > 0.00001){
            let t_p = 0;

            this.wares.feed.forEach((item, i) => {
                const mcal = item.mcal * new_qty.feed[i];
                const prot = item.prot * new_qty.feed[i];
                const vit = item.vit * new_qty.feed[i];
                const min = item.min * new_qty.feed[i];
                wares.feed.mcal += mcal;
                wares.feed.prot += prot;
                wares.feed.vit += vit;
                wares.feed.min += min;
                t_p += item.price * new_qty.feed[i];

                feed_source.mcal[i] += mcal;
                feed_source.prot[i] += prot;
                feed_source.vit[i] += vit;
                feed_source.min[i] += min;

                feed_source.feed_cm[i] = (new_qty.feed[i] + old_herd_feed_source.mcal[i]) > 0 ? item.cm : 0;

                if(new_qty.futures[i] > 0) wares.futures.push(this.wares.futures[i]);
                
                if(new_qty.cattle[i] > 0) wares.cattle.push(this.wares.cattle[i]);
            });

            if(t_p > 0){
                if(wares.feed.mcal > 0) wares.feed.mcalp = t_p / (fsum * wares.feed.mcal);
                if(wares.feed.prot > 0) wares.feed.protp = t_p / (fsum * wares.feed.prot);
                if(wares.feed.vit > 0) wares.feed.vitp = t_p / (fsum * wares.feed.vit);
                if(wares.feed.min > 0) wares.feed.minp = t_p / (fsum * wares.feed.min);
            }
            for(let i = 0; i < this.wares.feed.length; i++){
                if(feed_source.mcal[i] > 0 && wares.feed.mcal > 0) feed_source.mcal[i] = (feed_source.mcal[i] + (old_herd_feed_source.mcal[i] * wares.feed.mcal)) / (wares.feed.mcal);
                else feed_source.mcal[i] = old_herd_feed_source.mcal[i];

                if(feed_source.prot[i] > 0 && wares.feed.prot > 0) feed_source.prot[i] = (feed_source.prot[i] + (old_herd_feed_source.prot[i] * wares.feed.prot)) / (wares.feed.prot);
                else feed_source.prot[i] = old_herd_feed_source.prot[i];

                if(feed_source.vit[i] > 0 && wares.feed.vit > 0) feed_source.vit[i] = (feed_source.vit[i] + (old_herd_feed_source.vit[i] * wares.feed.vit)) / (wares.feed.vit);
                else feed_source.vit[i] = old_herd_feed_source.vit[i];

                if(feed_source.min[i] > 0 && wares.feed.min > 0) feed_source.min[i] = (feed_source.min[i] + (old_herd_feed_source.min[i] * wares.feed.min)) / (wares.feed.min);
                else feed_source.min[i] = old_herd_feed_source.min[i];
            }
        }else{
            feed_source.mcal = old_herd_feed_source.mcal;
            feed_source.prot = old_herd_feed_source.prot;
            feed_source.vit = old_herd_feed_source.vit;
            feed_source.min = old_herd_feed_source.min;
            feed_source.feed_cm = old_herd_feed_source.feed_cm;
        }

        wares.futures = wares.futures.concat(this.open_futures);
        this.open_futures = wares.futures;
        
        return [wares, feed_source];
    }
    getTotal(array){
        const vals = [0.00001, 0.00001, 0.00001];
        for(let i = 0; i < array[0].length; i++){
            for(let j = 0; j < vals.length; j++){
                vals[j] += array[j][i];
            }
        }
        return vals;
    }
    closeFuture(idx){
        this.open_futures[idx].status = "CLOSED";
    }
    loadMarket(obj){
        this.quit();
        this.treasury = obj.market.treasury;
        this.flat_bull_bear = obj.market.flat_bull_bear;
        this.today = new Date(obj.market.today).getTime();
        this.milk = obj.market.milk;
        this.milk_history = obj.market.milk_history;
        this.initialize();
    }
}





class StoreFeed{
    constructor(){
        this.feed = [
            {
                type:"Grass",
                item:"Grass, 100kg",
                c:"prot 14%, vit 4%, min 11%",
                mcal:250.956,
                prot:14100,
                vit:422.4,
                min:10910,
                kg:100,
                price:0.01,
                cm:0.5,
            },{
                type:"Hay",
                item:"Hay, 100kg",
                c:"prot 8.6%, vit 1%, min 7%",
                mcal:396.7495,
                prot:8600,
                vit:914,
                min:7250,
                kg:100,
                price:2,
                cm:0.1,
            },{
                type:"Concentrate",
                item:"Concentrate, 5kg",
                c:"prot 16%, vit 40%, min 40%",
                mcal:10,
                prot:824,
                vit:2075,
                min:2060,
                kg:5,
                price:3,
                cm:0.1,
            },{
                type:"Water",
                item:"Water, 1,000kg",
                c:"prot <1%, vit <1%, min 1%",
                mcal:0.001,
                prot:10,
                vit:10,
                min:10,
                kg:1000,
                price:0.001,
                cm:0.1,
            },
        ];
    }
}

class StoreFuture{
    constructor(){
        this.item = "Short 100kg";
        this.id = Math.random();
        this.days_to_expiry = Math.floor(Math.random() * 100) + 5;
        this.price = 0;
        this.initial_price = 0;
        this.sprice = 0.0085;
        this.kprice = 0.0085;
        this.kg_milk = Math.round(Math.random() * 1000) + 20;
        this.status = "OPEN";
        this.interest = 0.1;
        this.profit = 0;
        this.bought = true;
    }
    createNew(milk_price){
        this.sprice = milk_price;
        this.kprice = milk_price;

        this.price = this.computePrice();
        this.initial_price = parseFloat(this.price);

        this.item = "Short " + (this.kg_milk).toString() + "kg";

        this.initialize(milk_price, false);
    }
    computePrice(){
        return (this.sprice * (1 + (this.interest * (Math.max(this.days_to_expiry, 0.0001) / 365.25)))) * this.kg_milk;
    }
    initialize(milk_price, next_day){
        if(this.status === "OPEN"){
            this.sprice = milk_price;
            this.price = this.computePrice();
            this.profit = (this.initial_price - this.price);
        }
        if(next_day) this.days_to_expiry --;
    }
    loadFuture(future){
        this.item = future.item;
        this.id = future.id;
        this.days_to_expiry = future.days_to_expiry;
        this.price = future.price;
        this.initial_price = future.initial_price;
        this.sprice = future.sprice;
        this.kprice = future.kprice;
        this.kg_milk = future.kg_milk;
        this.status = future.status;
        this.interest = future.interest;
        this.profit = future.profit;
        this.bought = future.bought;
    }
}

export class StoreCow{
    constructor(obj){
        this.item = "Cattle";
        this.factors = "";
        this.id = obj === undefined ? window.crypto.getRandomValues(new Uint32Array(10)).toString().replace(/,/g,"0") : obj.id;
        this.gender = obj === undefined ? ["Female", "Male"][Math.round(Math.random() * 1)] : obj.gender;
        this.breed = obj === undefined ? ["Holstein"][0] : obj.breed;
        this.age = obj === undefined ? Math.floor(Math.random() * 45) : obj.age;
        this.weight = obj === undefined ? Math.floor(Math.random() * 1000) : obj.weight;
        this.days_spent_with_mother_as_calf = obj === undefined ? 14 : obj.days_spent_with_mother_as_calf;
        this.genetic_factor = obj === undefined ? Math.random() * 1.2 : obj.genetic_factor;
        this.fertility = obj === undefined ? Math.random() * 1.2 : obj.fertility;

        this.lactation_n = obj === undefined ? Math.floor(Math.random() * 20) : obj.lactation_n;

        this.sick = obj === undefined ? [true, false][Math.floor(Math.random() * 1)] : obj.sick;
        if(obj !== undefined){
            this.lactating = obj.lactating;
            this.days_after_calving = obj.days_after_calving;
            this.dry = obj.dry;
            this.days_after_dry = obj.days_after_dry;
            this.inseminate = obj.inseminate;
            this.production_modifier = obj.production_modifier;
            this.daily_milk = obj.daily_milk;
            this.daily_prot = obj.daily_prot;
            this.daily_fat = obj.daily_fat;
            this.total_milk_production = obj.total_milk_production;
            this.yearly_milk_production = obj.yearly_milk_production;
            this.inseminated = obj.inseminated;
            this.days_after_insemination = obj.days_after_insemination;

            this.call_vet = obj.call_vet;
            this.force_feed = obj.force_feed;

            this.daily_food_need = obj.daily_food_need;
            this.daily_food_intake = obj.daily_food_intake;

            this.comfort_level = obj.comfort_level;

            this.price = obj.price;
            this.daily_cost = obj.daily_cost;

            this.genetic_factor = obj.genetic_factor;
            this.factor = obj.factor;

            this.history = obj.history;
            this.feedback = obj.feedback;
            
            this.sell = obj.sell;
        }
    }
    initialize(){
        this.item = this.breed + ", " + this.gender[0] + ", " + this.age  + "Y, " + this.weight.toFixed(1) + "kg";
        this.factors = (this.fertility * this.genetic_factor * 100).toFixed(2);
        this.generatePrice();
    }
    generatePrice(){
        this.price = ((7 / Math.max(1, this.age)) * this.weight * goals_per_age[this.breed].kg * goals_per_age[this.breed].milk * this.genetic_factor * this.fertility * 3 * 3 * (Math.max(1, this.lactation_n) / 4)) / 400000;
    }
};