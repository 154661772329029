import { decisionFeedback } from './decisions.js';
export function getText(lang){  
  const text = {
    en:[
        {
          snackbar:{
            info:["There aren't any bulls available. Buying an insemination dosis costs 0.5 XMR. Using your own bulls is free ＿〆(。。)"],
            error:["Sorry, we were unable to load your save file (⌯˃̶᷄ ﹏ ˂̶᷄⌯)", "Sorry, you do not have any compatible save files ((╬ಠิ﹏ಠิ))", decisionFeedback.error[0], decisionFeedback.error[1]],
            success:[decisionFeedback.success[0], "Game saved \\( ﾟヮﾟ)/", "Data updated ٩(◕‿◕｡)۶", "Game successfully loaded \\( ﾟヮﾟ)/", decisionFeedback.success[1]],
            warning:["I think there's a dead animal in the pasture. I will take care of it (⚆_⚆)", "Hey there's no money left ( ｡_｡)",],
          },
          landing:{
            p:"A Simple Dairy Farm Management Simulator",
            h2:"Learn the basics of cattle management & milk production.",
            nav:["Resume Current", "New Simulation", "Load Instance"],
          },
          mininav:[
            {
              svg:"icons/monero.svg",
              title:"Treasury (estimated daily profit) XMR",
              unit:"XMR",
              sign:"+",
              round:100,
              sub:{
                title:"Profit breakdown",
                array:["From cattle", "From feed purchases", "From cattle purchases", "From future contracts", "From sold cattle", "From milk production", "Total daily profit"],
              },
            },{
              svg:"icons/bolt-24px_dark.svg",
              title:"Megacalories (daily use) Mcal",
              unit:"Mcal",
              sign:"-",
              round:100,
              sub:{
                title:"Mcal intake by source",
                array:["From grass", "From hay", "From concentrate", "From water"],
              },
            },{
              svg:"icons/protein_dark.svg",
              title:"Protein (daily use) g",
              unit:"g",
              sign:"-",
              round:10,
              sub:{
                title:"Protein intake by source",
                array:["From grass", "From hay", "From concentrate", "From water"],
              },
            },{
              svg:"icons/vitamins_dark.svg",
              title:"Vitamins (daily use) g",
              unit:"g",
              sign:"-",
              round:10,
              sub:{
                title:"Vitamin intake by source",
                array:["From grass", "From hay", "From concentrate", "From water"],
              },
            },{
              svg:"icons/mineral_dark.svg",
              title:"Minerals (daily use) g",
              unit:"g",
              sign:"-",
              round:10,
              sub:{
                title:"Mineral intake by source",
                array:["From grass", "From hay", "From concentrate", "From water"],
              },
            },{
              svg:"icons/water_dark.svg",
              title:"Water (daily use) kg",
              unit:"kg",
              sign:"-",
              round:10,
              sub:{
                title:"Water consumption breakdown",
                array:["Total intake", "Daily average (by head)"],
              },
            },{
              svg:"icons/cow_dark.svg",
              title:"Livestock",
              unit:"",
              sign:"",
              round:1,
              sub:{
                title:"Herd composition",
                array:["Females", "Males"],
              },
            },{
              svg:"icons/milk_dark.svg",
              title:"Milk production kg",
              unit:"kg",
              sign:"",
              round:100,
              sub:{
                title:"Milk production overview",
                array:["Produced", "Sold"],
              },
            },{
              svg:"icons/palm_dark.svg",
              title:"Herd comfort",
              unit:"",
              sign:"",
              round:10,
              sub:{
                title:"Comfort modifiers",
                array:["From health", "From grass", "From hay", "From concentrate", "From water", "Decision effects"],
              },
            },
          ],
          maintitle:["Daily production : ", "Daily production : ", "Market"],
          empty:"No cattle",
          next:"Next day",
          navigation:[
            {
              title:"Summary",
              feedback:"Herd overview",
              subtitle:["Situation overview", "Daily milk production", "All time production"],
              buttons:["Production chart", "Milk solids", "Daily profit", "Treasury"],
              submenu:null,
            },{
              title:"Herd composition",
              feedback:"Detailed view",
              buttons:["Inseminate", "Call vet (0.5 XMR)", "Force feed", "Sell"],
              subtitle:["Individual data", "Daily milk production", "All time production"],
              submenu:[
                {
                  title:"All",
                },{
                  title:"Lactating",
                },{
                  title:"Inseminated",
                },{
                  title:"Parturient",
                },{
                  title:"Inseminatable",
                },{
                  title:"Dried out",
                },{
                  title:"Juveniles",
                },{
                  title:"Breeding bulls",
                },{
                  title:"New comers",
                },{
                  title:"Sick",
                },{
                  title:"Dead",
                }
              ],
            },{
              title:"Market",
              feedback:"Go to the market",
              submenu:[
                {
                  title:"Milk exchange",
                  buttons:["Max", "24H", "Real time"],
                  subtitle:["Milk", "Futures", "Manage your futures contracts. Future contracts can be opened or closed anytime. An expired contract will be automatically executed through your daily milk production. If milk production is insufficient, the remainder will be purchased from the market at the rate of the day. Once closed a contract usually takes a day or two to be executed."],
                },{
                  title:"Livestock",
                  buttons:null,
                },{
                  title:"Feed",
                  buttons:null,
                }
              ],
            },
          ],
          table:[
            [
              {
                th:"Day",
                width:"5%",
                tal:"left",
                img:false,
                unit:"",
              },{
                th:"Heads",
                width:"15%",
                tal:"right",
                img:true,
                unit:"",
              },{
                th:"Income",
                width:"15%",
                tal:"right",
                img:true,
                unit:"XMR",
              },{
                th:"Running costs",
                width:"15%",
                tal:"right",
                img:true,
                unit:"XMR",
              },{
                th:"Food",
                width:"10%",
                tal:"right",
                img:true,
                unit:"%",
              },{
                th:"Water",
                width:"10%",
                tal:"right",
                img:true,
                unit:"%",
              },{
                th:"24h",
                width:"15%",
                tal:"right",
                img:true,
                unit:"KG",
              },{
                th:"7d",
                width:"15%",
                tal:"right",
                type:"graph",
                img:false,
                unit:"",
              },
            ],[
              {
                th:"#",
                width:"10%",
                tal:"left",
                img:false,
                unit:"",
              },{
                th:"Breed",
                width:"15%",
                tal:"left",
                img:false,
                unit:"",
              },{
                th:"Age",
                width:"5%",
                tal:"left",
                img:false,
                unit:"Y",
              },{
                th:"Status",
                width:"20%",
                tal:"right",
                img:false,
                unit:"",
                type:"smiley",
              },{
                th:"Price",
                width:"20%",
                tal:"right",
                img:false,
                unit:"XMR",
              },{
                th:"24h",
                width:"10%",
                tal:"right",
                img:false,
                unit:"KG",
              },{
                th:"7d",
                width:"20%",
                tal:"right",
                type:"graph",
                img:false,
                unit:"",
              }
            ],[
              {
                th:"Item",
                width:"18%",
                tal:"left",
                img:false,
                unit:"",
              },{
                th:"Price per contract",
                width:"15%",
                tal:"left",
                img:false,
                unit:"XMR",
              },{
                th:["Exercise price/kg", "Genetic viability", "Composition"],
                width:"12%",
                tal:"right",
                img:false,
                unit:["XMR", "%", ""],
              },{
                th:["Expiry", "Expiry", "Mcal"],
                width:"10%",
                tal:"right",
                img:false,
                unit:["day(s)", "", ""],
              },{
                th:["Total value", "Total cost", "Total cost"],
                width:"10%",
                tal:"right",
                img:false,
                unit:"XMR",
              },{
                th:["To be opened", "Number of contracts", "Number of contracts"],
                width:"10%",
                tal:"right",
                img:false,
                unit:"",
              },{
                th:["Sell", "Purchase", "Purchase"],
                width:"25%",
                tal:"right",
                type:"button",
                img:false,
                unit:"",
              }
            ],
          ],
          subtable:[
            [
              {
                mth:"Overview",
                th:"Lactating",
                title:"A lactating cow produces milk",
                tal:"right",
                unit:"",
              },{
                th:"Dried out",
                title:"A dried out cow does not produce any milk",
                tal:"right",
                unit:"",
              },{
                th:"New comers",
                tal:"right",
                unit:"",
              },{
                th:"Parturient",
                title:"A parturient cow is about to deliver a calf",
                tal:"right",
                unit:"",
              },{
                th:"Inseminated",
                tal:"right",
                unit:"",
              },{
                th:"Ready for insemination",
                style:"strong",
                tal:"right",
                unit:"",
              },{
                th:"Sick",
                style:"strong",
                tal:"right",
                unit:"",
              },{
                th:"Recovered",
                tal:"right",
                unit:"",
              },{
                th:"Dead",
                tal:"right",
                unit:"",
              },{
                th:"Females",
                style:"notice",
                tal:"right",
                unit:"",
              },{
                th:"Male breeders",
                style:"notice",
                tal:"right",
                unit:"",
              },{
                th:"Juveniles",
                style:"notice",
                tal:"right",
                unit:"",
              },{
                th:"Total",
                style:"strong",
                tal:"right",
                unit:"",
              },
            ],[
              {
                mth:"Detailed overview",
                th:"Total milk production",
                style:"strong",
                tal:"right",
                unit:"KG",
              },{
                th:"Average yearly production",
                style:"notice",
                tal:"right",
                unit:"KG",
              },{
                th:"Estimated yearly production",
                style:"notice",
                tal:"right",
                unit:"KG",
              },{
                th:"Genetic viability",
                tal:"right",
                unit:"%",
              },{
                th:"Age",
                tal:"right",
                unit:"Y",
              },{
                th:"Weight",
                tal:"right",
                unit:"KG",
              },{
                th:"Weight increase",
                tal:"right",
                unit:"KG",
              },{
                th:"Number of offspring",
                tal:"right",
                unit:"",
              },{
                th:"Fertility",
                tal:"right",
                unit:"%",
              },{
                th:"Comfort",
                tal:"right",
                unit:"%",
              },{
                th:"Production modifier",
                tal:"right",
                unit:"%",
              },{
                th:"Lactating",
                tal:"right",
                unit:"",
              },{
                th:"Lactating since",
                tal:"right",
                unit:"Day(s)",
              },{
                th:"Dried out",
                tal:"right",
                unit:"",
              },{
                th:"Dried out since",
                tal:"right",
                unit:"Day(s)",
              },{
                th:"Inseminatable",
                tal:"right",
                unit:"",
              },{
                th:"Inseminated",
                tal:"right",
                unit:"",
              },{
                th:"Inseminated since",
                tal:"right",
                unit:"Day(s)",
              },{
                th:"Parturient",
                style:"notice",
                tal:"right",
                unit:"",
              },{
                th:"Days to delivery (estimation)",
                tal:"right",
                unit:"Day(s)",
              },{
                th:"Sick",
                style:"strong",
                tal:"right",
                unit:"",
              },{
                th:"Daily costs",
                tal:"right",
                unit:"XMR",
              },
            ],
          ],
          marketTable:[
            {
              th:"Item",
              width:"15%",
              tal:"left",
              img:false,
              unit:"",
            },{
              th:"Initial contract value",
              width:"10%",
              tal:"left",
              img:false,
              unit:"XMR",
            },{
              th:"Current contract value",
              width:"10%",
              tal:"right",
              img:false,
              unit:"XMR",
            },{
              th:"Profit",
              width:"10%",
              tal:"right",
              img:false,
              unit:"XMR",
            },{
              th:"Exercise price/kg",
              width:"10%",
              tal:"right",
              img:false,
              unit:"XMR",
            },{
              th:"Current price/kg",
              width:"10%",
              tal:"right",
              img:false,
              unit:"XMR",
            },{
              th:"Expiry",
              width:"10%",
              tal:"right",
              img:false,
              unit:"days(s)",
            },{
              th:"Status",
              width:"10%",
              tal:"right",
              img:false,
              unit:"",
            },{
              th:"Close",
              width:"10%",
              tal:"right",
              type:"button",
              img:false,
              unit:"",
            },
          ],
          shortcut:[null, null, null, null, {title:"Inseminate all", img:"./icons/input-24px_light.svg"}, null, null, {title:"Sell all", img:"./icons/sell-24px_light.svg"}, null, {title:"Heal all", img:"./icons/healing-24px_light.svg"}, null],
          donatePanel:["Donate with Paypal"],
          helpPanel:[
            {
              summary:"Simulation mechanics",
              subdetails:[
                {
                  summary:"Getting started",
                  p:"Tip : profitabilty is more often than not directly linked to the genetic viability of the herd",
                  subdetails:[
                    {
                      summary:"Game mecanics",
                      p:"Holymilky is a comprehensive dairy farm management simulator. Manage your own dairy farm and your own livestock, enjoy producing milk and selling it on the market. Master the intricacies of herd genetic selection and have fun with an elaborated and realistic market system.",
                    },{
                      summary:"Turns",
                      p:"Holymilky is a turn based game, where each turn is a day. This means that there is no time pressure to take decisions. On the other hand, all decisions taken will only take effect once the Next Turn button is pressed. The Next Turn button is the red and white hourglass button that appears at the bottom right side of your screen when a game is launched.",
                    },{
                      summary:"Game saving",
                      p:"Holymilky enables you to keep a saved file of your game and resume it when you wish to. There can only be one game saved at a time. Since save files are linked to your browser, deleting your history will remove the link to them, which will then automatically cause their destruction.",
                    },{
                      summary:"Cows",
                      p:"Cows are used to produce milk. In order to produce, they first need to be inseminated and then deliver a calf.",
                    },{
                      summary:"Bulls",
                      p:"Bulls are used to inseminate cows or are sold on the market. Unhealthy bulls shouldn't be kept since they reduce the quality of the global gene pool.",
                    },{
                      summary:"Juveniles",
                      p:"Juveniles are young cattle. They do not produce anything.",
                    },{
                      summary:"Feed and water",
                      p:"Feed and water can be purchased from the market. In order to maximize cattle growth, it is important to reach food and water requirement. If growth seems too slow, cattle can be force fed. Force feeding, if overdone, can have long term adverse effects.",
                    },{
                      summary:"Comfort modifiers",
                      p:"Comfort modifiers are dependent on food variety and overall satisfaction. A positive (>0) comfort level increases milk production and a negative (<0) comfort level decreases it.",
                    },{
                      summary:"Decision modifiers",
                      p:"Decisions will appear randomly throughout the simulation. Each decision has three possible outcomes, which can be either good, bad or nonexistent. Most of the time, refusing an offer will have no consequence on the simulation.",
                    }
                  ],
                },{
                  summary:"Milk production",
                  p:"When a cow delivers a calf, she starts her lactating period. This means that she will produce milk for about 300 days until she dries out. Produced milk is sold each day on the market. More milk means more revenue but more cows means more expenses. Cattle with high genetic viability are more profitable.",
                },{
                  summary:"Genetics",
                  p:"Genetic viability, fertility and weight are good indicators of a cow's or a bull's value and/or profitability. It is recommended to weed out bad genes in order to maximize herd health and milk production. An unhealthy bovine is rarely profitable.",
                },
              ],
            },{
              summary:"Herd management",
              subdetails:[
                {
                  summary:"Top bar",
                  p:"The top bar displays your treasury, current inventory, cattle, milk production and total herd comfort",
                },{
                  summary:"Summary",
                  p:"The summary page gives an overview of the current herd. All displayed data is focused on the cattle (income/losses/purchases from the market are not taken into account).",
                  subdetails:[
                    {
                      summary:"Day",
                      p:"Nth day since start",
                    },{
                      summary:"Heads",
                      p:"Total number of cows, bulls and juveniles in the herd",
                    },{
                      summary:"Income",
                      p:"Daily income from milk production",
                    },{
                      summary:"Running costs",
                      p:"Daily cattle upkeep",
                    },{
                      summary:"Food requirement",
                      p:"Daily food requirement of cattle",
                    },{
                      summary:"Water requirement",
                      p:"Daily water requirement of cattle",
                    },{
                      summary:"24h production",
                      p:"Total milk production of the 24 last hours",
                    },{
                      summary:"Weekly production (7d)",
                      p:"Evolution of the milk production over the last 7 days",
                    },{
                      summary:"Production chart",
                      p:"History of daily milk production",
                    },{
                      summary:"Milk solids",
                      p:"Distribution of solids in last day's milk production (protein and fat)",
                    },{
                      summary:"Daily profit",
                      p:"Historical daily profit",
                    },{
                      summary:"Treasury",
                      p:"Current state of the treasury",
                    },
                  ],
                },{
                  summary:"Herd composition",
                  p:"The herd composition interface gives you the ability to manage your cattle",
                  subdetails:[
                    {
                      summary:"Lactating cows",
                      p:"List of all cows currently producing milk. In order to lactate, a cow has to deliver a calf.",
                    },{
                      summary:"Inseminated cows",
                      p:"List of all cows that have been inseminated and are currently gestating. The gestation period is usually around 300 days.",
                    },{
                      summary:"Parturient cows",
                      p:"List of all gestating cows that are near delivery",
                    },{
                      summary:"Inseminatable cows",
                      p:"List of all cows that are ready to be inseminated. If insemination is successful, a cow should deliver a calf and start lactating after about 300 days.",
                    },{
                      summary:"Dried out cows",
                      p:"List of all cows that are currently not producing any milk anymore (resting after a lactation). Any insemination taking place during this period will likely fail.",
                    },{
                      summary:"Juveniles",
                      p:"List of all young cattle (under 2 years)",
                    },{
                      summary:"Breeding bulls",
                      p:"Breeding bulls form your breeding stock. They are used for insemination. If you do not have any available bulls, each insemination dosis will cost you 0.5 XMR (since you have to buy it). Healthy and viable bulls will maximize your chances of producing healthy offspring. As for cows, unhealthy bulls tend to reduce the quality of the herd's genetic pool.",
                    },{
                      summary:"New comers",
                      p:"All new livestock either bought, received or born",
                    },{
                      summary:"Sick animals",
                      p:"List of all sick animals that can either be tended to or left to heal. Usually, a healthy animal doesn't need a vet to recover.",
                    },{
                      summary:"Dead animals",
                      p:"List of all dead livestock",
                    },
                  ],
                }
              ],
            },{
              summary:"Market",
              subdetails:[
                {
                  summary:"Milk exchange",
                  p:"The milk exchange displays the current price per kilogram of milk. To hedge yourself against sudden price dips, you can sell future contracts.",
                  subdetails:[
                    {
                      summary:"Realtime milk price",
                      p:"The current price the market is ready to pay for a kilogram of milk. Unless you have open future contracts, this is the price at which your daily production will be sold.",
                    },{
                      summary:"Future contracts",
                      p:"When you open a future contract, you are making the promise that you will deliver a fixed amount of milk (kg) at a fixed date (the expiry date) and for a fixed price (the price per contract). Expired futures are automatically executed against your daily milk production. If your production is too low, the remainder will be bought from the market at the price of the day. Future contracts can be closed anytime, if you buy them back before their expiry. This also means you can speculate on the price of milk without necessarily needing to produce any at all (as long as you close the contract before expiry).",
                    },
                  ]
                },{
                  summary:"Livestock market",
                  p:"A place to buy additional livestock",
                },{
                  summary:"Feed market",
                  p:"A place to buy additional feed. Purchase orders can be automated.",
                },
              ],
            },{
              summary:"Close",
              subdetails:null,
            },
          ],
        }
      ],
    };
  return text[lang];
}


