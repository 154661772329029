import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, XAxis, YAxis, AreaChart, Area, CartesianGrid, Scatter, ScatterChart } from 'recharts';

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radian = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);
    return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomTooltip = ({ active, payload, label, tick, unit, type, lastItem }) => {
    if (active && payload !== undefined && payload[0] !== undefined){
        const lbl = tick === "date" ? new Date(payload[0].payload.date).toLocaleString() : type !== "scatter" ? label : ("Bos #" + payload[0].payload.id.toString());
        return (
            <div className="tooltip">
                {payload[1] !== undefined && type === "scatter" ? [
                    <p className="n_big" key="lbl_0">{lbl}</p>,
                    <p className="n_small" key="lbl_1">{payload[0].name + " " + payload[0].value.toFixed(2) + " " + unit[0] + " : " + payload[1].name + " " + payload[1].value.toFixed(2) + " " + unit[1]}</p>
                ]:[
                    <p className="n_big" key="lbl_0">{(unit[0] === "Day" ? unit[0] + " " : "") + lbl}</p>,
                    <p className="n_small" key="lbl_1">{unit[1] + " " + payload[0].value.toFixed(unit[1] === "XMR" ? 12 : 0)}</p>
                ]}
            </div>
        );
    }else if(lastItem !== undefined){
        const lbl = tick === "date" ? new Date(lastItem.date).toLocaleString() : lastItem.date;
        return (
            <div className="tooltip">
                <p className="n_big" key="lbl_0">{(unit[0] === "Day" ? unit[0] + " " : "") + lbl}</p>
                <p className="n_small" key="lbl_1">{unit[1] + " " + lastItem.value.toFixed(unit[1] === "XMR" ? 12 : 0)}</p>
            </div>
        );
    }
    return null;
};

const DataFormatter = (number) => {
    if(number > 1000000000){
      return (number/1000000000).toFixed(2) + 'B';
    }else if(number > 1000000){
      return (number/1000000).toFixed(2) + 'M';
    }else if(number > 1000){
      return (number/1000).toFixed(2) + 'K';
    }else{
      return number.toFixed(4);
    }
}


export class MiniChart extends React.Component{
    state = {
        colors:[
            "#1b5e20",
            "#4a148c",
            "#3e2723",
            "#b71c1c",
            "#0d47a1",
        ],
    };
    roundDate = (date) => this.props.data.interval !== 5760 ? new Date(Math.round(date / (this.props.data.interval * 15000)) * (this.props.data.interval * 15000)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : new Date(date).toLocaleDateString();
    render(){
        const s_l = this.props.data.series.length;
        return s_l > 0 ? (
            <div className={this.props.data.type + "-chart-wrapper " + this.props.data.size} style={this.props.addstyle}>
                {this.props.data.type === "line" ?
                    <ResponsiveContainer>
                        <AreaChart
                            data={this.props.data.series}
                            margin={{top: 0, right: 0, left: 0, bottom: 0}}
                        >
                            <defs>
                                <linearGradient id={"area_gradient_" + this.props.data.index.toString()} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={this.props.data.color} stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor={this.props.data.color} stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            {this.props.data.size === "big" ? [
                                <CartesianGrid key="rch0" strokeDasharray="1 1"/>,
                                <XAxis key="rch1" name="time" dataKey="date" type="number" scale="time" domain={['dataMin', 'dataMax']} unit={this.props.data.unit[0] === "Day" ? "" : this.props.data.unit[0]} tickFormatter={(t) => this.props.data.tick === "date" ? this.roundDate(t) : `${this.props.data.unit[0]} ${t.toString()}`}/>,
                                <YAxis key="rch2" domain={[dataMin => Math.max(0, dataMin - 0.001), "dataMax + 0.001"]} type="number" orientation="right" unit={this.props.data.unit[1]} tickFormatter={DataFormatter} hide={this.props.hideYAxis}/>,
                                <Tooltip key="rch4" content={<CustomTooltip tick={this.props.data.tick} unit={this.props.data.unit} lastItem={this.props.data.series[this.props.data.series.length - 1]}/>} position={{ x: 0, y: 0 }} wrapperStyle={{visibility: "visible"}}/>
                            ]: null}
                            <Area dot={false} type="linear" dataKey="value" stroke={this.props.data.color} fill={"url(#area_gradient_" + this.props.data.index.toString() +")"} animationDuration={500}/>
                        </AreaChart>
                    </ResponsiveContainer>
                : null}
                {this.props.data.type === "pie" ?
                    <div>
                        <div className="chart-wrapper">
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie 
                                        dataKey="value"
                                        data={this.props.data.series}
                                        labelLine={false} 
                                        label={renderCustomizedLabel}
                                    >
                                        {this.props.data.series.map((entry, index) => <Cell key={`cell-${index}`} fill={this.state.colors[index % this.state.colors.length]} />)}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="pieLegend">
                            <ul className="flex fstart wrap">
                                {this.props.data.series.map((item, i) =>
                                    <li key={i.toString() + item.name} style={{borderLeft:"8px solid " + this.state.colors[i % this.state.colors.length].toString()}}>{item.name + " (" + item.value.toFixed(2) + " g / 100g milk)"}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                : null}
                {this.props.data.type === "scatter" ?
                    <ResponsiveContainer>
                        <ScatterChart isAnimationActive={false}>
                            <CartesianGrid strokeDasharray="1 1"/>
                            <XAxis type="number" dataKey="z" name="Milk" unit={this.props.data.unit[0]}/>
                            <YAxis type="number" name="Solids" unit={this.props.data.unit[1]} orientation="right" hide={this.props.hideYAxis}/>
                            <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip tick={this.props.data.tick} unit={this.props.data.unit} type="scatter"/>} position={{ x: 0, y: 0 }}/>
                            <Scatter name="Protein" dataKey="x" data={this.props.data.series} fill="#1b5e20" margin={{top: 0, right: 0, left: 0, bottom: 0}}/>
                            <Scatter name="Fat" dataKey="y" data={this.props.data.series} fill="#4a148c" margin={{top: 0, right: 0, left: 0, bottom: 0}}/>
                        </ScatterChart>
                    </ResponsiveContainer>
                : null}
            </div>
        ) : null;
    }
}