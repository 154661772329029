import { Component } from "react";

export class DonatePanel extends Component{
    handleForm = (e) => {
        switch(parseInt(e.target.id)){
            case 0:
                this.handlePaypal();
                break;
            default:
                this.handlePaypal();
                break;
        }
        this.props.toggleDonate();
    }
    handlePaypal = () => {
        const form = document.createElement("form");
        form.name = "paypal";
        form.method = "post";
        form.action = "https://www.paypal.com/donate";
        form.target = "_top";

        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "hosted_button_id";
        input.value = "YWYW5ERE8MS6Q";

        form.appendChild(input);
        document.body.appendChild(form);

        form.submit();
        document.body.removeChild(form);
    }
    render(){
        return(
            <div className="help_panel details absolute">
                <ul>
                    {this.props.lng.map((item, i) => <li id={i.toString() + "_donate_panel"} key={i.toString() + "_donate_panel"} className="hover" onClick={this.handleForm}>{item}</li>)}
                </ul>
            </div>
        );
    }
}
