import { Component } from "react";

export class Landing extends Component{
    handleClick = (e) => {
        const n = parseInt(e.currentTarget.id);
        switch(n){
            case 0:
                this.props.handleNav([0, 0]);
                break;
            case 1:
                this.props.handleNav([0, 0], this.props.resume > 1 ? true : undefined);
                break;
            case 2:
                this.props.handleLoadGame();
                break;
            default:
                this.props.handleNav([0, 0]);
                break;
        }
    }
    render(){
        return(
            <div className="landing-container flex fcolumn fspbe">
                <aside>
                    <p className="landing-note _up">Version {this.props.version}</p>
                </aside>
                <main className="flex wrap fcolumn fspev">
                    <div className="center">
                        <h2>{this.props.lng.landing.h2}</h2>
                        <p className="landing_p">{this.props.lng.landing.p}</p>
                    </div>
                    <div className="flex fcenter">
                        <img src="./icons/cow_dark.svg" alt="holymilky.com"/>
                    </div>
                    <nav className="flex wrap fcolumn fcenter fspbe">
                        <ul className="flex wrap fcenter lul">
                            {this.props.lng.landing.nav.map((item, i) =>
                                <li id={i.toString() + "_landing"} key={i.toString() + "_landing"} className={"center btn-outlined hover " + (i === 1 ? "dark" : "med")} onClick={this.handleClick} title={item}>{item}</li>
                            )}
                        </ul>
                    </nav>
                </main>
                <aside className="flex fspbe wrap">
                    <p className="landing-note">{'Copyright © holymilky.com ' + new Date().getFullYear() + '.'}</p>
                    <p className="landing-note"><a href="https://tutorlatin.com/" title="TutorLatin">TutorLatin</a></p>
                </aside>
            </div>
        );
    }
}