import { Component } from "react";
import { MiniChart } from "../../graphs/chart.js";
import { SimpleTable } from "./table.js";
import { n2s } from '../functions/textHandler.js';

export class DataLayout extends Component{
    state = {
        currentSelected:0,
    };
    handleAction = (e) => {
        if(this.props.nav[0] !== 1){
            this.setState({currentSelected: parseInt(e.currentTarget.id)});
        }else{
            this.props.handleBosManagement(parseInt(e.currentTarget.id));
        }
    }
    getClassName = (i) => {
        if(this.props.nav[0] === 1){
            switch(i){
                case 0:
                    return this.props.herd.herd[this.props.currentSelected].inseminate;
                case 1:
                    return this.props.herd.herd[this.props.currentSelected].call_vet;
                case 2:
                    return this.props.herd.herd[this.props.currentSelected].force_feed;
                case 3:
                    return this.props.herd.herd[this.props.currentSelected].sell;
                default:
                    return false;
            }
        }else{
            return false;
        }
    }
    getColors = (array) => {
        if(array.length > 0) return array[array.length - 1].value > array[0].value ? ["#16c784", "↑"] : array[array.length - 1].value < array[0].value ? ["#ea3943", "↓"] : ["#333d54", "→"];
        else return ["#333d54", "→"];
    }
    render(){
        const charts = [
            {
                data:this.props.herd.chart[this.props.nav[0]][0],
                color:this.getColors(this.props.herd.chart[this.props.nav[0]][0]),
                units:["Day", "kg"],
            },{
                data:this.props.herd.chart[this.props.nav[0]][1],
                color:this.getColors(this.props.herd.chart[this.props.nav[0]][1]),
                units:this.props.nav[0] === 0 ? ["kg", "g"] : ["Day", "kg"],
            },{
                data:this.props.nav[0] === 0 ? this.props.herd.chart[0][2] : [],
                color:this.getColors(this.props.nav[0] === 0 ? this.props.herd.chart[0][2] : []),
                units:["Day", "XMR"],
            },{
                data:this.props.nav[0] === 0 ? this.props.herd.chart[0][3] : [],
                color:this.getColors([]),
                units:["Day", "XMR"],
            },
        ];

        const txt = this.props.lng.navigation[this.props.nav[0]];
        const sbtl = this.props.nav[0] === 0 ? [charts[0].data[charts[0].data.length - 1].value, this.props.herd.total_milk_production] : [this.props.herd.herd[this.props.currentSelected].daily_milk, this.props.herd.herd[this.props.currentSelected].total_milk_production];
        return(
            <div className="g_container flex wrap fspev">
                <div className="g_item flex fcolumn">
                    <div className="block_header flex wrap">
                        <div className="fgrow1">
                            <h3>{txt.subtitle[0]}</h3>
                            <p>{txt.subtitle[1]}&nbsp;<span style={{color:charts[0].color[0]}}>{charts[0].color[1]}&nbsp;{n2s(sbtl[0], 100)}</span>&nbsp;<span className="unit">KG</span></p>
                            {txt.subtitle[2] !== undefined ? <p>{txt.subtitle[2]}&nbsp;<span>{n2s(sbtl[1], 100)}</span>&nbsp;<span className="unit">KG</span></p> : null}
                        </div>
                        <div className="flex wrap fend" style={{marginTop:"auto"}}>
                            {txt.buttons !== null ? txt.buttons.map((item, i) =>
                                <div className="navbtn secondary" key={i.toString() + "_navbtn_1"}>
                                    <button id={i.toString() + "_001"} className={this.getClassName(i) ? "btn-outlined dark" : "btn-outlined med"} style={{maxHeight:"100%"}} onClick={this.handleAction} title={item}>{item}</button>
                                </div>
                            ): null}
                        </div>
                    </div>
                    <SimpleTable
                        data={this.props.herd.subtable[this.props.nav[0]]}
                        subtable={this.props.lng.subtable[this.props.nav[0]]}
                        money_unit={this.props.money_unit}
                        addstyle={null}
                    />
                </div>
                <div className="g_item flex fcolumn">
                    {(this.props.nav[0] === 0 && (this.state.currentSelected !== 1)) || this.props.nav[0] === 1 ?
                        <div className="table-content" style={{marginTop:"auto", paddingBottom: "0"}}>
                            <MiniChart
                                data={{
                                    type:this.props.nav[0] === 0 ? "line" : "pie",
                                    size:"big",
                                    series:this.props.sampleArray(charts[this.props.nav[0] === 0 ? this.state.currentSelected : 0].data, 60, null),
                                    interval:1,
                                    color:charts[this.props.nav[0] === 0 ? this.state.currentSelected : 0].color[0],
                                    tick:"number",
                                    unit:charts[this.props.nav[0] === 0 ? this.state.currentSelected : 0].units,
                                    index:"data_0",
                                }}
                                addstyle={null}
                                hideYAxis={this.props.hideYAxis}
                            />
                        </div>
                    : [
                        <div className="pieLegend" key="rch0" style={{marginTop:"auto"}}>
                            <ul className="flex fstart wrap">
                                <li style={{borderLeft:"8px solid #1b5e20"}}>Protein (g / 100g milk)</li>
                                <li style={{borderLeft:"8px solid #4a148c"}}>Fat (g / 100g milk)</li>
                            </ul>
                        </div>,
                        <div className="table-content" key="rch1" style={{paddingBottom: "0"}}>
                            <MiniChart
                                data={{
                                    type:"scatter",
                                    size:"big",
                                    series:this.props.sampleArray(charts[1].data, 200, null),
                                    interval:1,
                                    color:charts[1].color[0],
                                    tick:"number",
                                    unit:charts[1].units,
                                    index:"data_1",
                                }}
                                addstyle={null}
                                hideYAxis={this.props.hideYAxis}
                            />
                        </div>
                    ]}
                    {this.props.nav[0] === 1 ?
                        <div className="table-content" style={{marginTop:"auto", paddingBottom: "0"}}>
                            <MiniChart
                                data={{
                                    type:"line",
                                    size:"big",
                                    series:this.props.sampleArray(charts[1].data, 60, null),
                                    interval:1,
                                    color:charts[1].color[0],
                                    tick:"number",
                                    unit:charts[1].units,
                                    index:"data_2",
                                }}
                                addstyle={null}
                                hideYAxis={this.props.hideYAxis}
                            />
                        </div>
                    : null}
                </div>
            </div>
        );
    }
}