import { Component } from "react";
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Fab from '@mui/material/Fab';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { getText } from "./components/text/text.js";
import { Banner } from "./components/layout/banner.js";
import { Herd, MarketPlace } from "./components/main/functions/classes.js";
import { SaveFile, checkSaveFile } from "./components/main/functions/saveUtil.js";
import { Landing } from "./components/layout/landing.js";
import { Table } from "./components/main/layout/table.js";
import { MarketLayout } from "./components/main/layout/marketlayout.js";
import { DataLayout } from "./components/main/layout/datalayout.js";
import { HelpPanel } from "./components/main/layout/help.js";
import { DonatePanel } from "./components/main/layout/donate.js";
import { n2s } from './components/main/functions/textHandler.js';

import "./index.css";

const theme = createTheme({
    palette: {
      primary: {
        main: '#333d54',
        contrastText: '#f6f9fc',
      },
    },
    typography:{
        fontFamily: "'Ubuntu', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Helvetica Neue', 'Droid Sans', sans-serif"
    },
});

/*TODO campaigns : use load game to load scenarios (little background story: family farm, no money left, cows are suffering from malnutrition, will you be able to save them and make the farm profitable again ?)*/

class App extends Component {
    constructor(props) {
      super(props);
        this.state = {
            language:getText("en"),
            snackMessage:{
                state:false,
                message:"",
                severity:"error",
            },
            nav:[3, 0],
            herd:null,
            market:null,
            money_unit:10000,
            onSelected:false,
            currentSelected:0,
            wares:{
                feed:[100, 10, 10, 1000],
                cattle:[0, 0, 0, 0],
                futures:[0, 0, 0, 0],
            },
            sell_indices: [],
            showpp:[0, false],
            autoPurchase:[false, false, false, false],
            treas_low_lim: -10,
            toggleHelp: false,
            toggleDonate: false,
            hideYAxis:!!window.matchMedia('(max-width: 812px)').matches,
            decisionModal: false
        }
   
        this.toggleSnackMessage = this.toggleSnackMessage.bind(this);
        this.handleNav = this.handleNav.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.handleSaveGame = this.handleSaveGame.bind(this);
        this.handleLoadGame = this.handleLoadGame.bind(this);
        this.handleNextDay = this.handleNextDay.bind(this);
        this.handleBosManagement = this.handleBosManagement.bind(this);
    }
    setLanguage(lang){
        if(lang !== "en") lang = "en";
        this.setState({ language: getText(lang) });
    }
    toggleSnackMessage(a, b, c){
        if(b === "") return null;
        const sm = {
            state: a,
            message: !isNaN(parseInt(c)) ? this.state.language[0].snackbar[b][parseInt(c)] : c.toString(),
            severity: b.toString(),
        };
        this.setState({ snackMessage: sm });
    }
    handleSaveGame(){
        localStorage.removeItem(this.props.storage);
        const savefile = new SaveFile(this.props.version, this.state.herd, this.state.market, this.state);
        localStorage.setItem(this.props.storage, JSON.stringify(savefile));
        this.toggleSnackMessage(true, "success", 1);
    }
    handleLoadGame(){
        const savefile = JSON.parse(localStorage.getItem(this.props.storage));
        const csf = checkSaveFile(savefile, this.props.acceptedVersions);
        if(savefile !== null && csf === 0){
            const new_herd = this.state.herd;
            const feedback = new_herd.loadSaveGame(savefile, this.props.version);

            const new_market = this.state.market;
            new_market.loadMarket(savefile);
            new_market.open_futures = new_herd.open_futures;

            if(feedback === "success"){
                this.setState({
                    herd : new_herd,
                    market : new_market,
                    wares : savefile.setstate.wares,
                    autoPurchase : savefile.setstate.autoPurchase,
                    sell_indices : savefile.setstate.sell_indices,
                    nav: [0, 0],
                });
                this.toggleSnackMessage(true, "success", 3);
            }else{
                this.toggleSnackMessage(true, "error", 0);
            }
        }else{
            this.toggleSnackMessage(true, "error", 1);
        }
    }
    handleNav(a, x){
        this.setState({
            nav:a,
            onSelected:false,
        });
        if(x !== undefined && x === true){
            this.state.market.quit();
            this.setState({
                wares:{
                    feed:[100, 10, 10, 1000],
                    cattle:[0, 0, 0, 0],
                    futures:[0, 0, 0, 0],
                },
                sell_indices: [],
                autoPurchase: [false, false, false, false],
            }, () => this.componentDidMount());
        }
    }
    handleClick = (e) => this.handleNav(e.currentTarget.id.split("_").map(n => parseInt(n)));
    onSelect = (e) => {
        const n = parseInt(e.currentTarget.id);
        const herd = this.state.herd;
        if(!isNaN(n) && n < herd.herd.length && this.state.nav[0] === 1){
            herd.generateIndividualData(n);
            
            this.setState({
                onSelected: !this.state.onSelected, 
                currentSelected: parseInt(e.currentTarget.id),
                herd: herd,
            });
        }
        return null;
    }
    componentDidMount(){
        const market = new MarketPlace(100, 0.0085, new Date(new Date().setHours(4)), [], [0, 0], []);
        market.initialize();

        const herd = new Herd([0, 0, 0, 0], market.treasury);

        const num_test = 20;
        const bos_arr = new Array(num_test);
        for(let i = 0; i < num_test; i ++){
            bos_arr[i] = {gender: ["Male", "Female"][Math.floor(Math.random() * 2)], breed: "Holstein", age: Math.random() * 60, weight: Math.random() * 800, dmc:Math.random() * 14, gf: Math.random() * 1.2, random: Math.random()};
        }

        herd.addHeads(bos_arr, "new");

        const [wares, feed_source] = market.closeTrades(this.state.wares, herd.feed, herd.feed_source);
        herd.updateInventory(market.wares, this.state.wares, this.state.autoPurchase, wares, feed_source);
        herd.initialize();

        this.setState({
            herd: herd,
            market : market,
            wares : {
                feed: [0, 0, 0, 0],
                cattle: [0, 0, 0, 0],
                futures : [0, 0, 0, 0],
            },
        });
        window.addEventListener("resize", this.handleResize.bind(this));
    }
    componentWillUnmount(){
        if(this.state.market !== null) this.state.market.quit();
    }
    handleResize = () => this.setState({hideYAxis: !!window.matchMedia('(max-width: 812px)').matches});
    handleNextDay(){
        const l = this.state.market.milk.length - 1;
        const old_market = this.state.market;
        old_market.quit();

        const next_herd = this.state.herd;
        if(this.state.sell_indices.length > 0) next_herd.sell(this.state.sell_indices);
        const [wares, feed_source] = old_market.closeTrades(this.state.wares, next_herd.feed, next_herd.feed_source);
        next_herd.updateInventory(this.state.market.wares, this.state.wares, this.state.autoPurchase, wares, feed_source);
        next_herd.updateMilkPrice(this.state.market.milk[l].value);
        next_herd.initialize();

        const next_market = new MarketPlace(this.state.herd.treasury, this.state.market.milk[l].value, new Date(this.state.market.today), this.state.market.milk_history, this.state.market.flat_bull_bear, this.state.herd.open_futures);
        next_market.initialize();
        next_market.treasury = next_herd.treasury;

        const feed = new Array(this.state.wares.feed.length);
        for(let i = 0; i < feed.length; i++){
            feed[i] = this.state.autoPurchase[i] && next_herd.treasury > this.state.treas_low_lim ? this.state.wares.feed[i] : 0;
        }

        if(next_herd.decision.active && next_herd.decision.valid === next_herd.decision.maxValidity - 1) this.toggleSnackMessage(true, next_herd.decision.displayStatus(), next_herd.decision.feedback);
        else if(next_herd.subtable[0][8] > 0) this.toggleSnackMessage(true, "warning", 0);
        
        this.setState({
            herd: next_herd,
            market: next_market,
            onSelected: false,
            currentSelected: 0,
            wares : {
                feed: feed,
                cattle: [0, 0, 0, 0],
                futures : [0, 0, 0, 0],
            },
            sell_indices: [],
            autoPurchase: next_herd.treasury > this.state.treas_low_lim ? this.state.autoPurchase : [false, false, false, false],
            decisionModal: next_herd.decision.open && !next_herd.decision.active
        });
    }
    sampleArray = (array, max_l, g) => {
        const incr = Math.floor(array.length / max_l);
        if(incr > 1){
            if(g !== 1){
                const arr = new Array(max_l);
                for(let i = 0, x = 0; x < array.length; i++, x += incr){
                    arr[i] = array[x];
                }
                if(arr[arr.length - 1].date !== array[array.length - 1].date) arr.push(array[array.length - 1]);
                return arr;
            }else{
                return array.slice(array.length - max_l);
            }
        }else{
            return array;
        }
    }
    handleBosManagement = (idx) => {
        const update_herd = this.state.herd;
        update_herd.manage(idx, this.state.currentSelected);

        const arr = idx === 3 ? this.handleSellIndices([...this.state.sell_indices], this.state.currentSelected) : [...this.state.sell_indices];

        if(idx === 0){
            if(update_herd.subtable[0][10] === 0 && update_herd.herd[this.state.currentSelected].inseminate) this.toggleSnackMessage(true, "info", 0);
        }
        
        this.setState({
            herd: update_herd,
            sell_indices: arr,
        });
    }
    handleSellIndices = (initial_array, idx) => {
        const arr = [...initial_array];
        const index = arr.indexOf(idx);
            if(index >= 0){
                arr.splice(index, 1);
            }else if(index < 0){
                arr.push(idx);
            }
        return arr;
    }
    handleShowPP = (e) => {
        const n = parseFloat(e.currentTarget.id);
        const nr = [...this.state.showpp];
        nr[0] = n;
        nr[1] = this.state.showpp[0] !== nr[0] && nr[1] ? nr[1] : !nr[1];
        this.setState({showpp: nr});
    }
    retrieveMarketData = () => {
        switch(this.state.nav[1]){
            case 0:
                return this.ltk(["item", "price", "kprice", "days_to_expiry", "state_val", "state_num", "id"], this.state.market.wares.futures);
            case 1:
                return this.ltk(["item", "price", "factors", "expiry", "state_val", "state_num", "id"], this.state.market.wares.cattle);
            case 2:
                return this.ltk(["item", "price", "c", "mcal", "state_val", "state_num", "id"], this.state.market.wares.feed);
            default:
                return [];
        }
    }
    retrieveFutureData = () => {
        if(this.state.market.open_futures.length > 0){
            return this.ltk(["item", "initial_price", "price", "profit", "kprice", "sprice", "days_to_expiry", "status", "id"], this.state.market.open_futures);
        }else{
            return [];
        }
    }
    ltk = (key_array, base_array) => {
        const num_rows = base_array.length;
        const g = ["futures", "cattle", "feed"][this.state.nav[1]];
        const new_array = new Array(num_rows);
        for(let j = 0; j < num_rows; j++){
            const row = new Array(key_array.length);
            for(let i = 0; i < key_array.length; i++){
                row[i] = key_array[i] !== "state_num" ?
                    key_array[i] !== "state_val" ?
                        base_array[j][key_array[i]] !== undefined ?
                            [base_array[j][key_array[i]], 0]
                        :
                            ["None", 0]
                    : 
                        [this.state.wares[g][j] * base_array[j].price, 0]
                :
                    [this.state.wares[g][j], 0];
            }
            new_array[j] = row;
        }
        return new_array;
    }
    handleChange = (e) => {
        if(this.state.herd.treasury > this.state.treas_low_lim){
            const g = ["futures", "cattle", "feed"][this.state.nav[1]];
            const indic = e.currentTarget.id.split("_");
            if(indic[1] !== "auto"){
                const update_wares = this.state.wares;
                if(indic[1] === "+" && (g === "feed" || (g !== "feed" && update_wares[g][parseInt(indic[0])] < 1))) update_wares[g][parseInt(indic[0])] ++;
                else if(indic[1] === "-" && update_wares[g][parseInt(indic[0])] > 0) update_wares[g][parseInt(indic[0])] --;
                this.setState({wares: update_wares});
            }else{
                const ap = [...this.state.autoPurchase];
                ap[indic[0]] = !this.state.autoPurchase[indic[0]];
                this.setState({autoPurchase: ap});
            }
        }else{
            this.toggleSnackMessage(true, "warning", 1);
        }
    }
    handleCloseFuture = (e) => {
        const n = parseInt(e.currentTarget.id);
        const update_market = this.state.market;
        update_market.closeFuture(n);
        this.setState({market: update_market});
    }
    handleShortcut = () => {
        const update_herd = this.state.herd;
        const new_sell_indices = update_herd.groupAction(this.state.nav[1]);
        const update_sell_indices = new_sell_indices.length > 0 ? [...this.state.sell_indices].concat(new_sell_indices) : [...this.state.sell_indices];
        this.setState({
            herd: update_herd,
            sell_indices: update_sell_indices,
        });
        if(this.state.nav[1] === 4 && update_herd.subtable[0][10] === 0) this.toggleSnackMessage(true, "info", 0);
    }
    toggleHelp = () => this.setState({toggleHelp: !this.state.toggleHelp, toggleDonate: false});
    toggleDonate = () => this.setState({toggleDonate: !this.state.toggleDonate, toggleHelp: false});
    handleAnchor = (i) => {
        const x = 170;
        const max_width = window.innerWidth - 32;
        const n_per_window = Math.floor(max_width / x);
        const o = x * (i % n_per_window);
        return (o + 300) <= max_width ? o : (max_width - 300);
    }
    handleDecision = b => {
        let herd = this.state.herd;
        if(!b) herd.decision.close();
        else herd.decision.select();
        this.setState({
            decisionModal: false,
            herd: herd
        });
    }
    render(){
        const lng = this.state.language[0];
        return this.state.herd !== null ? (
            <ThemeProvider theme={theme}>
                <div id="container">
                    <Snackbar
                        autoHideDuration={null}
                        open={this.state.snackMessage.state}
                        onClose={() => this.toggleSnackMessage(false,this.state.snackMessage.severity,0)}>
                        <Alert
                            onClose={() => this.toggleSnackMessage(false,this.state.snackMessage.severity,0)}
                            severity={this.state.snackMessage.severity.toString()}
                            variant="filled"
                        >
                                {this.state.snackMessage.message}
                        </Alert>
                    </Snackbar>
                    {this.state.herd.decision.current &&
                        <Modal
                            open={this.state.decisionModal}
                            onClose={(e, r) => r !== "backdropClick" ? this.handleDecision(false) : e.stopPropagation()}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className="modal-container">
                                <h4 id="modal-modal-title">{this.state.herd.decision.current.h}</h4>
                                <p id="modal-modal-description">{this.state.herd.decision.current.q}</p>
                                <div className="flex wrap fend">
                                    <button onClick={() => this.handleDecision(false)} className="btn-outlined light">{this.state.herd.decision.current.options[1]}</button>
                                    <button onClick={() => this.handleDecision(true)} className="btn-outlined dark">{this.state.herd.decision.current.options[0]}</button>
                                </div>
                            </div>
                        </Modal>
                    }
                    {!this.state.onSelected && this.state.nav[0] === 1 && (this.state.nav[1] === 4 || this.state.nav[1] === 7 || this.state.nav[1] === 9) ?
                        <div className="fabdiv left flex wrap fend fcolumn">
                            <Fab variant="extended" style={{backgroundColor:"#333d54", color:"#f8fafd"}} title={lng.shortcut[this.state.nav[1]].title} className="fab" size="medium" aria-label={lng.shortcut[this.state.nav[1]].title} onClick={this.handleShortcut}>
                                <img style={{height:"24px",width:"24px", marginRight:"5px"}} src={lng.shortcut[this.state.nav[1]].img} alt="action"/> {lng.shortcut[this.state.nav[1]].title}
                            </Fab>
                        </div>
                    : null}
                    {this.state.nav[0] !== 3 ?
                        <div className="fabdiv right flex wrap fend fcolumn">
                            <Fab variant="extended" color="secondary" title={lng.next} className="fab" size="medium" aria-label={lng.next} onClick={this.handleNextDay}>
                                {lng.next} <img style={{height:"24px",width:"24px"}} src="./icons/hourglass_full-24px_light.svg" alt="next"/>
                            </Fab>
                        </div>
                    : null}
                    <header className="top">
                        <Banner
                            handleNav={this.handleNav}
                            nav={this.state.nav}
                            lng={lng}
                            toggleHelp={this.toggleHelp}
                            toggleDonate={this.toggleDonate}
                            handleSaveGame={this.handleSaveGame}
                        />
                        {this.state.nav[0] !== 3 ?
                            <div className="sub-banner lb">
                                <aside>
                                    <ul className="flex fstart wrap relative">
                                        {this.state.language[0].mininav.map((item, i) =>
                                            <li key={i.toString() + "mininav"} id={i.toString() + "mininav"} className="hover sb_li_mn" title={item.title} onClick={this.handleShowPP}><img src={item.svg} alt={item.title}/>
                                                <span style={this.state.herd.inventory[i].main <= 0 ? {color:"rgb(234, 57, 67)"} : null}>{n2s(this.state.herd.inventory[i].main, item.round)} {this.state.herd.inventory[i].sec !== null ? "(" + (this.state.herd.inventory[i].sec > 0 ? item.sign : "") + (n2s(this.state.herd.inventory[i].sec, item.round)).toString() + ")" : ""}</span>
                                                {item.sub !== null ?
                                                    <div className="absolute details" style={this.state.showpp[1] && this.state.showpp[0] === i ? {display: "inline-block", left:`${this.handleAnchor(i)}px`} : {left:`${this.handleAnchor(i)}px`}}>
                                                        <ul>
                                                            <li><strong>{item.sub.title}</strong></li>
                                                            {item.sub.array.map((el, j) => <li key={j.toString() + "_sub_details"}><span>{el}</span><span>{n2s(this.state.herd.inventory[i].sub[j], item.round)}&nbsp;<span className="unit">{item.unit}</span></span></li>)}
                                                        </ul>
                                                    </div>
                                                : null}
                                            </li>
                                        )}
                                    </ul>
                                </aside>
                            </div>
                        : null}
                        {this.state.toggleHelp ? <HelpPanel toggleHelp={this.toggleHelp} lng={lng.helpPanel}/> : null}
                        {this.state.toggleDonate ? <DonatePanel toggleDonate={this.toggleDonate} lng={lng.donatePanel}/> : null}
                    </header>
                    {this.state.nav[0] !== 3 ? [
                        <main className="main-container g_container" key="0_m_d">
                            <div className="g_item">
                                <nav className="flex wrap fstart sim-nav">
                                    {lng.navigation.map((item, i) =>
                                        <div className="navbtn" key={i.toString() + "_navbtn_0"}>
                                            <button id={i.toString() + "_000"} className={this.state.nav[0] !== i ? "btn-outlined medl" : "btn-outlined med"} style={{maxHeight:"100%"}} onClick={this.handleClick} title={item.feedback}>{item.title}</button>
                                        </div>
                                    )}
                                    <div className="fbreak"></div>
                                    {lng.navigation[this.state.nav[0]].submenu !== null ? lng.navigation[this.state.nav[0]].submenu.map((item, i) =>
                                        <div className="navbtn secondary" key={this.state.nav[0].toString() + "_" + i.toString() + "_navbtn_1"}>
                                            <button id={this.state.nav[0].toString() + "_" + i.toString()} className={this.state.nav[1] !== i ? "btn-outlined medl" : "btn-outlined med"} style={{maxHeight:"100%"}} onClick={this.handleClick} title={item.title}>{item.title}</button>
                                        </div>
                                    ) : null}
                                </nav>
                            </div>
                            <div className="g_item">
                                <Table
                                    data={this.state.nav[0] < 2 ? this.state.herd.table[this.state.nav[0]] : this.retrieveMarketData()}
                                    suppldata={this.state.herd.subtable[0]}
                                    nav={this.state.nav}
                                    lng={lng.table[this.state.nav[0]]}
                                    tableClass={"tbl_main"}
                                    money_unit={this.state.money_unit}
                                    onSelect={this.onSelect}
                                    currentSelected={this.state.currentSelected}
                                    onSelected={this.state.onSelected}
                                    itemSize={88}
                                    handleChange={this.handleChange}
                                    autoPurchase={this.state.autoPurchase}
                                />
                                {this.state.nav[0] === 0 || ((this.state.nav[0] === 1 || this.state.nav[0] === 2) && this.state.onSelected) ?
                                    <DataLayout
                                        herd={this.state.herd}
                                        nav={this.state.nav}
                                        lng={lng}
                                        n2s={n2s}
                                        money_unit={this.state.money_unit}
                                        currentSelected={this.state.currentSelected}
                                        sampleArray={this.sampleArray}
                                        handleBosManagement={this.handleBosManagement}
                                        hideYAxis={this.state.hideYAxis}
                                    />
                                : null}
                                {this.state.nav[0] === 2 && this.state.nav[1] === 0 ?
                                    <MarketLayout
                                        market={this.state.market}
                                        herd={this.state.herd}
                                        nav={this.state.nav}
                                        lng={lng}
                                        n2s={n2s}
                                        money_unit={this.state.money_unit}
                                        sampleArray={this.sampleArray}
                                        openFutures={this.retrieveFutureData()}
                                        handleCloseFuture={this.handleCloseFuture}
                                        hideYAxis={this.state.hideYAxis}
                                    />
                                : null}
                            </div>
                        </main>,
                        <footer key="1_m_d">
                            <div className="flex wrap">
                                <p><span>{'Copyright © holymilky.com ' + new Date().getFullYear() + '.'}</span>&nbsp;| <span className="hover" onClick={() => this.setLanguage("en")}> EN</span></p>
                            </div>
                        </footer>
                    ]:
                        <Landing lng={lng} resume={this.state.herd.history.length} handleNav={this.handleNav} handleLoadGame={this.handleLoadGame} version={this.props.version}/>
                    }
                </div>
            </ThemeProvider>
        ) : null;
    }
}

const root = createRoot(document.getElementById('root'));
root.render(
    <App 
        version="1.4.0"
        acceptedVersions={["1.4.0", "1.3.0"]}
        storage="urj8190sksnbapqlaalajj19adh765gapisna3vaymkap1o39766gdhabslportb419s0dhha4ua"
    />
);
