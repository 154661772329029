import { Component } from "react";

export class HelpPanel extends Component{
    render(){
        return(
            <div className="help_panel details absolute">
                <ul>
                    {this.props.lng.map((item, i) =>
                        <li key={i.toString() + "_help_panel"} className="hover" onClick={i !== this.props.lng.length - 1 ? () => null : this.props.toggleHelp}>
                            {i !== this.props.lng.length - 1 ?
                                <details title={item.summary}>
                                    <summary>{item.summary}</summary>
                                    <ul>
                                        {item.subdetails.map((el, j) =>
                                            <li key={j.toString() + "_help_details_" + i.toString()}>
                                                <details>
                                                    <summary>{el.summary}</summary>
                                                    <p>{el.p}</p>
                                                    {el.subdetails !== undefined ?
                                                        <ul>
                                                            {el.subdetails.map((ele, k) =>
                                                                <li key={j.toString() + "_help_details_" + i.toString() + "_" + k.toString()}>
                                                                    <details>
                                                                        <summary>{ele.summary}</summary>
                                                                        <p>{ele.p}</p>
                                                                    </details>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    : null}
                                                </details>
                                            </li>
                                        )}
                                    </ul>
                                </details>
                                :
                                    item.summary
                                }
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}