import { Component } from "react";
import { MiniChart } from "../../graphs/chart.js";
import { MarketActions } from "./marketaction.js";
import IconButton from '@mui/material/IconButton';
import { n2s } from '../functions/textHandler.js';

export class Table extends Component{
    state = {
        start: 0,
        end: Math.floor((window.innerWidth) / this.props.itemSize),
        window: 0,
        max_visible: 0,
        fullSize: this.props.itemSize * this.props.data.length,
    };
    currentSelection = (item) => {
        if(this.props.nav[0] !== 1){
            return true;
        }else{
            this.selection = this.props.nav[1];
            switch(this.props.nav[1]){
                case 0:
                    return true;
                case 1:
                    return item[3][1].lactating === 1;
                case 2:
                    return item[3][1].inseminated;
                case 3:
                    return item[3][1].parturient === 1;
                case 4:
                    return item[3][1].inseminatable === 1;
                case 5:
                    return item[3][1].dry === 1;
                case 6:
                    return item[2][0] <= 2;
                case 7:
                    return item[3][1].gender === "Male" && item[2][0] >= 1;
                case 8:
                    return item[3][1].new_comer === 1;
                case 9:
                    return item[3][2];
                case 10:
                    return item[3][1].death === 1;
                default:
                    return true;
            }
        }
    }
    getClassName = (m, i) => {
        if(this.props.nav[0] === 0){
            switch(i){
                case 0:
                    return "_same";
                case(3):
                    return m[1] < 0 ? "_up" : m[1] !== 0 ? "_down" : "_same";
                case(4):
                    return m[0] > 100 ? "_up" : m[0] !== 100 ? "_down" : "_same";
                case(5):
                    return m[0] > 100 ? "_up" : m[0] !== 100 ? "_down" : "_same";
                default:
                    return m[1] > 0 ? "_up" : m[1] !== 0 ? "_down" : "_same";
            }
        }else if(this.props.nav[0] === 2 && this.props.nav[1] === 0 && i === 3){
            return m[0] > 0 ? "_up" : m[0] !== 0 ? "_down" : "_same";
        }
    }
    getSmileys = (m) => {
        const g = () => {
            if(!m[2]){
                if(m[0] > 1) return 3;
                else if(m[0] >= 0.5) return 2;
                else if(m[0] > 0) return 0;
                else if(m[0] <= 0) return 1;
            }else{
                return 4;
            }
        };
        const ttl = ["Gender", "Dead", "Comfort index : " + n2s(m[0], 100), "Insemination status", "Cure status", "Dried out", "Lactating", "Ready for insemination", "New comer", "Parturient"];
        const comfort = [
            [
                "./icons/female.svg", "./icons/male.svg"
            ][m[1].gender === "Female" ? 0 : 1],[
                null, "./icons/not_interested-24px_red.svg"
            ][m[1].death],[
                "./icons/sentiment_dissatisfied-24px_orange.svg",
                "./icons/sentiment_very_dissatisfied-24px_red.svg",
                "./icons/sentiment_satisfied-24px_green.svg",
                "./icons/sentiment_very_satisfied-24px_green.svg",
                "./icons/sick-24px_red.svg",
            ][g()],[
                null, "./icons/input-24px_green.svg", "./icons/input-24px_red.svg"
            ][m[1].insemination],[
                null, "./icons/health_and_safety-24px_green.svg", "./icons/health_and_safety-24px_red.svg" 
            ][m[1].cure],[
                null, "./icons/format_color_reset-24px_orange.svg"
            ][m[1].dry],[
                null, "./icons/opacity-24px_green.svg"
            ][m[1].lactating],[
                null, "./icons/input-24px_orange.svg"
            ][m[1].inseminatable],[
                null, "./icons/star_rate-24px_yellow.svg"
            ][m[1].new_comer],[
                null, "./icons/watch_later-24px_orange.svg"
            ][m[1].parturient]
        ];
        return comfort.map((item, i) => item !== null ? <img key={i.toString()} src={item} alt={ttl[i]} title={ttl[i]}/> : null);
    }
    getImg = (m, i) => this.props.lng[i].img ? m[1] > 0 ? "↑" : m[1] < 0 ? "↓" : "→" : i !== 0 ? "" : "#";
    getNum = (m, i) => n2s(i !== 0 || this.props.nav[0] !== 1 ? m[0] : m[0] + 1, this.props.lng[i].unit || this.props.lng[i].unit[this.props.nav[1]] === "XMR" ? this.props.money_unit : 100);
    getColor = (m) => m[m.length - 1].value > m[0].value ? "#16c784" : m[0].value > m[m.length - 1].value ? "#ea3943" : "#333d54";
    handleScroll = (e) => {
        const max_visible = this.state.window < window.innerHeight ? this.getNumItemsPerWindow(window.innerHeight) : this.state.max_visible;
        const start = Math.round(e.target.scrollTop / this.props.itemSize);
        if(start !== this.state.start && !isNaN(start)){
            const end = start + max_visible;
            this.setState({
                start: start,
                end: Math.min(end, this.props.data.length),
                window: window.innerHeight,
                max_visible: max_visible,
            });
        }
    }
    componentDidUpdate(){
        if(this.state.fullSize !== this.getTotalSize()){
            this.setState({
                fullSize: this.getTotalSize(),
            });
        }
    }
    getNumItemsPerWindow = (cy) => Math.ceil(cy / this.props.itemSize);
    getTotalSize = () => {
        if(this.props.nav[0] === 1){
            switch(this.props.nav[1]){
                case 0:
                    return this.props.data.length * this.props.itemSize;
                case 1:
                    return this.props.suppldata[0] * this.props.itemSize;
                case 2:
                    return this.props.suppldata[4] * this.props.itemSize;
                case 3:
                    return this.props.suppldata[3] * this.props.itemSize;
                case 4:
                    return this.props.suppldata[5] * this.props.itemSize;
                case 5:
                    return this.props.suppldata[1] * this.props.itemSize;
                case 6:
                    return this.props.suppldata[11] * this.props.itemSize;
                case 7:
                    return this.props.suppldata[10] * this.props.itemSize;
                case 8:
                    return this.props.suppldata[2] * this.props.itemSize;
                case 9:
                    return this.props.suppldata[6] * this.props.itemSize;
                case 10:
                    return this.props.suppldata[8] * this.props.itemSize;
                default:
                    return this.props.data.length * this.props.itemSize;
            }
        }else{
            return this.props.data.length * this.props.itemSize;
        }
    }
    render(){
        return this.props.data.length > 0 ? (
            <div className={this.props.suppldata !== "remove" ? "table-content" : ""}>
                <div className="table-overflow" onScroll={this.handleScroll}>
                    <table className={this.props.tableClass.toString()}>
                        <thead>
                            <tr>
                                {this.props.lng.map((l, k) => <th className={l.tal} key={k.toString() + "th" + l.th.toString().toLowerCase()} style={{width: l.width}}>{!Array.isArray(l.th) ? l.th : l.th[this.props.nav[1]]}</th>)}
                            </tr>
                        </thead>
                        <Tbody
                            nav={this.props.nav}
                            onSelect={this.props.onSelect}
                            lng={this.props.lng}
                            data={this.props.data}
                            money_unit={this.props.money_unit}
                            getClassName={this.getClassName}
                            getSmileys={this.getSmileys}
                            getImg={this.getImg}
                            getNum={this.getNum}
                            getColor={this.getColor}
                            onSelected={this.props.onSelected}
                            currentSelected={this.props.currentSelected}
                            currentSelection={this.currentSelection}
                            start={this.state.start}
                            end={this.state.end}
                            fullSize={this.state.fullSize}
                            itemSize={this.props.itemSize}
                            handleChange={this.props.handleChange}
                            autoPurchase={this.props.autoPurchase}
                            suppldata={this.props.suppldata}
                        />
                    </table>
                </div>
            </div>
        )
        :
        (
            <div className="table-content">
                <p>{this.props.lng.empty}</p>
            </div>
        );
    }
}

const Tbody = (props) => {
    let incr = -1;
    return (
        <tbody style={!props.onSelected ? {height: props.fullSize.toString() + "px"} : {height: props.itemSize.toString() + "px"}}>
            {props.data.map((row, idx) => {
                if(props.onSelected && props.currentSelected !== idx){
                    return null;
                }else{
                    if(props.currentSelection(row)){
                        incr ++;
                        if(props.onSelected || (incr >= props.start && incr <= props.end)){
                            return (
                                <Row
                                    key={idx.toString() + "_row"}
                                    idx={idx}
                                    incr={incr}
                                    nav={props.nav}
                                    onSelect={props.onSelect}
                                    lng={props.lng}
                                    data={row}
                                    money_unit={props.money_unit}
                                    getClassName={props.getClassName}
                                    getSmileys={props.getSmileys}
                                    getImg={props.getImg}
                                    getNum={props.getNum}
                                    getColor={props.getColor}
                                    itemSize={props.itemSize}
                                    handleChange={props.handleChange}
                                    autoPurchase={props.autoPurchase}
                                    suppldata={props.suppldata}
                                />
                            );
                        }else{
                            return (<tr key={idx.toString() + "_row"} style={{opacity:"0", zIndex:"-1"}}></tr>);
                        }
                    }else{
                        return null;
                    }
                }
            })}
        </tbody>
    );
}

const Row = (props) => {
    return (
        <tr style={{top: props.itemSize * props.incr}} id={props.idx.toString() + "_row_" + props.nav[0].toString()} key={props.idx.toString() + "_row_" + props.nav[0].toString()} onClick={props.onSelect}>
            {props.data.map((m, i) =>
                <td className={props.lng[i].tal} key={props.idx.toString() + "_" + i.toString() + "_td"} title={!Array.isArray(props.lng[i].th) ? props.lng[i].th : props.lng[i].th[props.nav[1]]} style={{width: props.lng[i].width}}>
                    {i !== props.data.length - 1 ? 
                        props.lng[i].type !== "smiley" ?
                            <span className={props.getClassName(m, i)}>{props.getImg(m, i)}&nbsp;{props.getNum(m, i)}&nbsp;<span className="unit">{!Array.isArray(props.lng[i].unit) ? props.lng[i].unit : props.lng[i].unit[props.nav[1]]}</span></span>
                            :
                            <span className="_same flex fend">{props.getSmileys(m)}</span>
                        :
                        <span>
                            {props.lng[i].type === "graph" ?
                                <MiniChart
                                    data={{
                                        type:"line",
                                        size:"small",
                                        series:m,
                                        color:props.getColor(m),
                                        tick:"none",
                                        unit:"",
                                        index:props.incr,
                                    }}
                                    addstyle={null}
                                />
                            :
                                <MarketActions
                                    idx={props.idx}
                                    handleChange={props.handleChange}
                                    autoPurchase={props.autoPurchase}
                                    nav={props.nav}
                                    suppldata={props.suppldata}
                                />
                            }
                        </span>
                    }
                </td>
            )}
        </tr>
    );
}


export class SimpleTable extends Component{
    state = {
        showTable: false,
    };
    handleTable = () => this.setState({showTable: !this.state.showTable});
    render(){
        return this.props.data.length > 0 ? (
            <div className="table-content" style={this.props.addstyle}>
                <div>
                    <table className="break-table-sm tbl_sec">
                        {this.props.subtable[0].mth !== undefined ?
                            <thead>
                                <tr>
                                    <th title={this.props.subtable[0].mth} colSpan="2">{this.props.subtable[0].mth.toUpperCase()} <span className="-sm"><IconButton aria-label="toggle" title="toggle" onClick={this.handleTable}><img src={`./icons/${this.state.showTable ? "arrow_drop_up_black_24px_dark.svg" : "arrow_drop_down_24px_dark.svg"}`} alt="toggle"/></IconButton></span></th>
                                </tr>
                            </thead>
                        : null}
                        <tbody className={!this.state.showTable ? "-nsm" : ""}>
                            {this.props.data.map((item, k) =>
                                <tr key={k.toString() + "_table_"} title={this.props.subtable[k].title !== undefined ? this.props.subtable[k].title : ""}>
                                    <th className={this.props.subtable[k].style !== undefined && item > 0 ? this.props.subtable[k].style : ""}>{this.props.subtable[k].th}</th>
                                    <td className={this.props.subtable[k].tal} key={k.toString() + "_" + k.toString() + "_td"} title={this.props.subtable[k].th}>{n2s(item, this.props.subtable[k].unit !== "XMR" ? 100 : this.props.money_unit)}&nbsp;<span className="unit">{this.props.subtable[k].unit}</span></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : null;
    }
}
/*

<td colSpan={this.props.table.length.toString()} className="center">
    <span><IconButton id="0add" onClick={this.props.onChecked}><img src="src_icons/add-24pxdark.svg" alt="add"/></IconButton></span>
</td>

*/