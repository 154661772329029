import { Component } from "react";
import IconButton from '@mui/material/IconButton';

export class Banner extends Component{
    render(){
        return(
            <div className="banner lb flex fspbe">
                <h1 onClick={() => this.props.handleNav([3, 0])}>Holy Milky</h1>
                <nav className="flex fend fvcent wrap">
                    <IconButton title="Donate" onClick={this.props.toggleDonate}><img src="icons/favorite-24pxred.svg" alt="donate"/></IconButton>
                    {this.props.nav[0] !== 3 ?
                        <div>
                            <IconButton onClick={this.props.handleSaveGame}><img src="icons/save-24px_dark.svg" alt="save" title="Save"/></IconButton>
                        </div>
                    : null}
                    <div>
                        <IconButton onClick={this.props.toggleHelp}><img src="icons/help-24px_dark.svg" alt="help" title="Help"/></IconButton>
                    </div>
                    <div>
                        <IconButton onClick={() => this.props.handleNav([3, 0])}><img src="icons/home-24px_dark.svg" alt="home" title="Home"/></IconButton>
                    </div>
                </nav>
            </div>
        );
    }
}