import IconButton from '@mui/material/IconButton';

export const MarketActions = (props) => {
    return(
        <span className="flex fspev">
            <IconButton id={props.idx.toString() + "_-_ibtn"} style={{backgroundColor:"rgb(234, 57, 67)"}} aria-label="remove one" title="remove one" onClick={props.handleChange}><img src="./icons/remove-24px_light.svg" alt="remove one"/></IconButton>
            {props.suppldata !== "remove" ? <IconButton id={props.idx.toString() + "_+_ibtn"} style={{backgroundColor:"rgb(22, 199, 132)"}} aria-label="add one" title="add one" onClick={props.handleChange}><img src="./icons/add-24px_light.svg" alt="add one"/></IconButton> : null}
            {props.nav[1] === 2 ? <IconButton id={props.idx.toString() + "_auto_ibtn"} style={props.autoPurchase[props.idx] ? {backgroundColor:"#5b6383"} : {backgroundColor:"#DFE0E1"}} aria-label="toggle auto purchase" title="toggle auto purchase (saves current order and repeats it every day)" onClick={props.handleChange}><img src="./icons/autorenew-24px_light.svg" alt="auto buy"/></IconButton> : null}
        </span>
    );
}