import { StoreCow } from "./classes";

export class SaveFile{
    constructor(version, herd, market, state){
        this.last_updated = new Date().getTime();
        this.version = version;
        this.setstate = {
            autoPurchase: state.autoPurchase,
            wares: state.wares,
            sell_indices: state.sell_indices,
        };
        this.herd = {
            herd: herd.herd.map(item => new StoreCow(item)),
            milk_price: herd.milk_price,
            treasury: herd.treasury,
            autoPurchase: herd.autoPurchase,
            market_actions: herd.market_actions,
            total_milk_production: herd.total_milk_production,
            feed: herd.feed,
            feed_source: herd.feed_source,
            comfort_modifiers: herd.comfort_modifiers,
            open_futures: herd.open_futures,
            closed_futures: herd.closed_futures,
            comfort: herd.comfort,
            total_daily_food_need: herd.total_daily_food_need,
            total_daily_food_intake: herd.total_daily_food_intake,
            table: herd.table,
            subtable: herd.subtable,
            chart: herd.chart,
            inventory: herd.inventory,
            history: herd.history,
            clean_dead: herd.clean_dead,
            increment: herd.increment,
            reproductors: herd.reproductors,
            decision: herd.decision
        };
        this.market = {
            treasury: market.treasury,
            flat_bull_bear: market.flat_bull_bear,
            today: market.today,
            milk: [market.milk[market.milk.length - 1]],
            milk_history: market.milk_history,
            open_futures: market.open_futures,
        };
    }
};

export const checkSaveFile = (sf, versions) => {
    try{
        let ok = 0;
        if(versions.indexOf(sf.version) === -1) ok++;
        if(!Array.isArray(sf.setstate.autoPurchase) || sf.setstate.autoPurchase.length !== 4) ok++;
        if(!Array.isArray(sf.setstate.wares.feed) || sf.setstate.wares.feed.length !== 4) ok++;
        if(!Array.isArray(sf.setstate.wares.cattle) || sf.setstate.wares.cattle.length !== 4) ok++;
        if(!Array.isArray(sf.setstate.wares.futures) || sf.setstate.wares.futures.length !== 4) ok++;
        if(!Array.isArray(sf.setstate.sell_indices)) ok++;
        
        if(!Array.isArray(sf.herd.herd)) ok++;
        
        if(isNaN(sf.herd.milk_price)) ok++;
        if(isNaN(sf.herd.treasury)) ok++;
        if(!Array.isArray(sf.herd.autoPurchase) || sf.herd.autoPurchase.length !== 4) ok++;
        
        Object.keys(sf.herd.market_actions).forEach(key => {
            if(key !== "sold_cattle" && key !== "feed_purchases" && key !== "cattle_purchases" && key !== "opened_future") ok++;
            if(isNaN(sf.herd.market_actions[key])) ok++;
        });
        if(isNaN(sf.herd.total_milk_production)) ok++;
        
        Object.keys(sf.herd.feed).forEach(key => {
            if(key !== "mcal" && key !== "mcalp" && key !== "prot" && key !== "protp" && key !== "vit" && key !== "vitp" && key !== "min" && key !== "minp" && key !== "water" && key !== "waterp") ok++;
            if(isNaN(sf.herd.feed[key])) ok++;
        });
        
        Object.keys(sf.herd.feed_source).forEach(key => {
            if(key !== "mcal" && key !== "prot" && key !== "vit" && key !== "min" && key !== "feed_cm") ok++;
            if(!Array.isArray(sf.herd.feed_source[key]) || sf.herd.feed_source[key].length !== 4) ok++;
        });
        
        if(!Array.isArray(sf.herd.comfort_modifiers) || sf.herd.comfort_modifiers.length !== 4) ok++;
        if(!Array.isArray(sf.herd.open_futures)) ok++;
        if(!Array.isArray(sf.herd.closed_futures)) ok++;
        if(isNaN(sf.herd.comfort)) ok++;
        
        Object.keys(sf.herd.total_daily_food_need).forEach(key => {
            if(key !== "mcal" && key !== "prot" && key !== "vit" && key !== "min" && key !== "water") ok++;
            if(isNaN(sf.herd.total_daily_food_need[key])) ok++;
        });
        Object.keys(sf.herd.total_daily_food_intake).forEach(key => {
            if(key !== "mcal" && key !== "prot" && key !== "vit" && key !== "min" && key !== "water") ok++;
            if(isNaN(sf.herd.total_daily_food_intake[key])) ok++;
        });
        
        if(!Array.isArray(sf.herd.table) || sf.herd.table.length !== 2) ok++;
        if(!Array.isArray(sf.herd.subtable) || sf.herd.subtable.length !== 2) ok++;
        if(!Array.isArray(sf.herd.chart) || sf.herd.chart.length !== 2) ok++;
        
        if(!Array.isArray(sf.herd.inventory)) ok++;
        if(!Array.isArray(sf.herd.history)) ok++;
        if(!Array.isArray(sf.herd.clean_dead)) ok++;
        if(isNaN(sf.herd.increment)) ok++;
        if(!Array.isArray(sf.herd.reproductors)) ok++;

        if(typeof sf.herd.decision !== "object") ok++;
        Object.keys(sf.herd.decision).forEach(key => {
            if(key !== "valid" && key !== "active" && key !== "current" && key !== "effectIdx" && key !== "effect" && key !== "feedback" && key !== "open" && key !== "originalValue" && key !== "maxValidity" && key !== "decisionFreq") ok++;
        });
        
        if(isNaN(sf.market.treasury)) ok++;
        if(!Array.isArray(sf.market.flat_bull_bear) || sf.market.flat_bull_bear.length !== 2 || isNaN(sf.market.flat_bull_bear[0]) || isNaN(sf.market.flat_bull_bear[1])) ok++;
        if(isNaN(sf.market.today)) ok++;
        
        if(!Array.isArray(sf.market.milk) || sf.market.milk.length !== 1 || isNaN(sf.market.milk[0].value) || isNaN(sf.market.milk[0].date)) ok++;
        if(!Array.isArray(sf.market.milk_history)) ok++;
        
        if(!Array.isArray(sf.market.open_futures)) ok++;
        
        for(let i = 0; i < 4; i++){
            if(isNaN(sf.herd.feed_source.mcal[i])) ok++;
            if(isNaN(sf.herd.feed_source.prot[i])) ok++;
            if(isNaN(sf.herd.feed_source.vit[i])) ok++;
            if(isNaN(sf.herd.feed_source.min[i])) ok++;
            if(isNaN(sf.herd.feed_source.feed_cm[i])) ok++;

            if(isNaN(sf.herd.comfort_modifiers[i])) ok++;

            if(isNaN(sf.setstate.autoPurchase[i])) ok++;
            if(isNaN(sf.herd.autoPurchase[i])) ok++;

            if(isNaN(sf.setstate.wares.futures[i])) ok++;
            if(isNaN(sf.setstate.wares.cattle[i])) ok++;
            if(isNaN(sf.setstate.wares.feed[i])) ok++;
        }
        return ok;
    }catch(e){
        return 99999;
    }
};