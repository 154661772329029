import { Component } from "react";
import { MiniChart } from "../../graphs/chart.js";
import { Table } from "./table.js";

export class MarketLayout extends Component{
    state = {
        chart_tick:1,
        currentSelected:2,
    };
    componentDidMount(){
        this.timerID = setInterval(() => this.tick(), this.props.market.interval);
    }
    componentWillUnmount(){
        clearInterval(this.timerID);
    }
    tick = () => this.setState({});
    handleAction = (e) => {
        const n = parseInt(e.currentTarget.id);
        this.setState({
            currentSelected: n,
            chart_tick: [5760, 240, 1][n],
        });
        this.tick();
    }
    render(){
        const d = this.props.sampleArray(this.state.currentSelected >= 1 ? this.props.market.milk : this.props.market.milk_history, 50, this.state.currentSelected === 2 ? 1 : null);
        const txt = this.props.lng.navigation[this.props.nav[0]].submenu[this.props.nav[1]];
        const color = d[d.length - 1].value > d[0].value ? ["#16c784", "↑"] : d[d.length - 1].value < d[0].value ? ["#ea3943", "↓"] : ["#333d54", "→"];
        return([
            <div className="table-content" key="0_table-content">
                <div className="block_header flex wrap">
                    <div className="fgrow1">
                        <h3>{txt.subtitle[0]}</h3>
                        <p><span style={{color:color[0]}}>{color[1] + " " + this.props.n2s(d[d.length - 1].value, this.props.money_unit)}</span> <span className="unit">XMR / KG</span></p>
                    </div>
                    <div className="flex wrap fend" style={{marginTop:"auto"}}>
                        {txt.buttons !== null ? txt.buttons.map((item, i) =>
                            <div className="navbtn secondary" key={i.toString() + "_navbtn_1"}>
                                <button id={i.toString() + "_001"} className={i === this.state.currentSelected ? "btn-outlined dark" : "btn-outlined med"} style={{maxHeight:"100%"}} onClick={this.handleAction} title={item}>{item}</button>
                            </div>
                        ): null}
                    </div>
                </div>
            </div>,
            <div className="table-content" key="1_table-content">
                <MiniChart
                    data={{
                        type:"line",
                        size:"big",
                        series:d,
                        interval:this.state.chart_tick,
                        color:color[0],
                        tick:"date",
                        unit:["", "XMR"],
                        index:"market_0",
                    }}
                    addstyle={null}
                    hideYAxis={this.props.hideYAxis}
                />
            </div>,
            <div className="table-content" key="2_table-content">
                <div className="block_header flex wrap">
                    <div className="fgrow1">
                        <h3>{txt.subtitle[1]}</h3>
                        <p>{txt.subtitle[2]}</p>
                    </div>
                </div>
                {this.props.openFutures.length > 0 ?
                    <Table
                        data={this.props.openFutures}
                        suppldata={"remove"}
                        nav={this.props.nav}
                        lng={this.props.lng.marketTable}
                        tableClass={"tbl_main"}
                        n2s={this.props.n2s}
                        money_unit={this.props.money_unit}
                        onSelect={this.onSelect}
                        currentSelected={this.props.currentSelected}
                        onSelected={this.props.onSelected}
                        itemSize={87}
                        handleChange={this.props.handleCloseFuture}
                    />
                :
                    <p className="center" style={{marginBottom:"30px"}}>No contracts</p>
                }
            </div>
            ]
        );
    }
}