/* decisions are stored in text.js; when added here, they should be readded to text.js */
export const decisionFeedback = {
    success: [
        "Thanks to your good decisions, herd comfort & milk production have increased"
    ],
    error: [
        "Following your last risky decision, herd comfort & milk production have decreased",
        "Following your last risky decision, some of your animals are suffering from food poisoning."
    ]
};
/***
* h: title
* q: question
* options: two options (order is important for arrays infra)
* effect:
*    type: currently only comfort is handled (cf. Herd.handleDecisionModifiers)
*    alts: multipliers (modifiers), 0 = no change, 1 = +100% (calculation: modifier + (modifier * multiplier)); order is important [error, success]
*    feedback: index in snackbar array (text.js) [error, success]
* outcome: (used in index.js handleNextDay, order of above arrays is very important!)
*    2 = good|bad
*    1 = good|better
*    0 = bad|worse
***/
export const decisions = [
    {
        h: "Power pills",
        q: "A man comes knocking at your door. He tells you he invented a pill that increases cattle happiness and lowers stress. \"Happy cows, as he says, eat more, produce more and mate more often\". However, since he works alone and is frankly unknown, no one is ready to buy it. He offers you 30 days worth of pills for free in order to get his name out there.",
        options: ["Yes, I'll try it", "Not interested"],
        effect: {
            type: "comfort",
            alts: [-1, 1],
            feedback: [2, 0]
        },
        outcome: 2
    },{
        h: "Greener pastures",
        q: "You neighbour offers you to exchange grazing pastures just for a day. His offer is the following: you would send your cattle onto his land and he would send his onto yours. Do you accept this offer?",
        options: ["Yes, let's do this", "Not interested"],
        effect: {
            type: "comfort",
            alts: [0, 1],
            feedback: [2, 0]
        },
        outcome: 2
    },{
        h: "Your cattle stinks",
        q: "You've recently realized that your cattle emits bad body odours. This could be due to a number of factors, namely an unbalanced diet, uncleaned pens or bad treatment. Would you like to do something about it?",
        options: ["Keep them outside", "They will get over it"],
        effect: {
            type: "comfort",
            alts: [0.1, 0.2],
            feedback: [0, 0]
        },
        outcome: 1
    },{
        h: "Depressed cattle",
        q: "For reasons you might be aware of, your cattle is sad. Would you like to do something about it?",
        options: ["Yes, let us play some Beethoven", "No, they will get over it"],
        effect: {
            type: "comfort",
            alts: [0, 0.2],
            feedback: [0, 0]
        },
        outcome: 1
    },{
        h: "A generous donation",
        q: "A local association has given you a sum of money to improve your herd's well-being. What would you like to do with this money?",
        options: ["Build a water fountain", "Buy a new television"],
        effect: {
            type: "comfort",
            alts: [0.1, 0.2],
            feedback: [0, 0]
        },
        outcome: 1
    },{
        h: "Cow rebellion",
        q: "Your cattle is acting wierd. Yesterday one the youngest of the herd tried to bite you and this morning he and another one seemed to be plotting against you. They do not usually act this way.",
        options: ["Give them some euphorbia", "They will get over it"],
        effect: {
            type: "comfort",
            alts: [-1, -0.5],
            feedback: [3, 3]
        },
        outcome: 0
    }
];