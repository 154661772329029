import { decisions } from '../../text/decisions.js';

export class Decision{
    constructor(){
        this.maxValidity = 30;
        this.valid = this.maxValidity;
        this.active = false;
        this.current = null;
        this.effectIdx = 0;
        this.effect = 0;
        this.feedback = 2;
        this.open = false;
        this.originalValue = 0;
        this.decisionFreq = 0.9;
        this.pastDecisions = [];
    }
    new(){
        try{
            let decisionIdx = Math.floor(Math.random() * decisions.length);
            let blocker = 0;
            while(this.pastDecisions.indexOf(decisionIdx) > -1 && blocker++ < 100){
                decisionIdx = Math.floor(Math.random() * decisions.length);
            }
            this.current = decisions[decisionIdx];
            this.effectIdx = Math.floor(Math.random() * this.current.effect.alts.length);
            this.effect = this.current.effect.alts[this.effectIdx];
            this.feedback = this.current.effect.feedback[this.effectIdx];
            this.open = true;
            this.pastDecisions.push(decisionIdx);
        }catch(e){
            this.effect = 0;
            this.open = false;
            this.active = false;
        }
    }
    close(){
        this.maxValidity = 30;
        this.valid = this.maxValidity;
        this.active = false;
        this.current = null;
        this.effectIdx = 0;
        this.effect = 0;
        this.feedback = 2;
        this.open = false;
        this.originalValue = 0;
    }
    nextTurn(){
        if(!this.open && !this.active){
            if(Math.random() > this.decisionFreq) this.new();
            return null;
        }
        if(!this.active || this.valid <= 0){
            this.active = false;
            return this.effect = 0;
        }
        this.valid--;
    }
    select(){
        this.valid = this.maxValidity;
        this.open = false;
        this.active = true;
    }
    load(obj){
        this.valid = obj.valid;
        this.active = obj.active;
        this.current = obj.current;
        this.effectIdx = obj.effectIdx;
        this.effect = obj.effect;
        this.feedback = obj.feedback;
        this.open = obj.open;
        this.originalValue = obj.originalValue;
    }
    displayStatus(){
        switch(this.current.outcome){
            case 0: return "error";
            case 1: return "success";
            case 2: return ["error", "success"][this.effectIdx];
            default: return "";
        }
    }
}