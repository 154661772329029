const p2s = num => {
    let n = num.toString().replace(/[^0-9.]/g, '');
    if (n < 1000) return n;
    let si = [
        {v: 1E3, s: "K"},
        {v: 1E6, s: "M"},
        {v: 1E9, s: "B"},
        {v: 1E12, s: "T"},
        {v: 1E15, s: "P"},
        {v: 1E18, s: "E"}
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) break;
    }
    return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
};

export const n2s = (n, dec, small) => {
    if(!isNaN(n)){
        if(!!small) return p2s(n);
        let z = n < 0 ? "-" : "";
        let a = Math.round(Math.abs(n) * parseFloat(dec))/parseFloat(dec);
        let b = a.toString().split(".");
        let c = b[0].split("").reverse();
        let d = [];
        while(c.length){
            let e = c.length < 3 ? c.splice(0, c.length) : c.splice(0, 3);
            d.push(e.reverse().toString().replace(/,/g,""));
        }
        return b[1] === undefined ? z + d.reverse().toString() : z + d.reverse().toString() + "." + b[1].toString();
    }else{
        return n.toString();
    }
};